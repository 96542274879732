var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-row items-center justify-around h-full w-full"},[_c('div',{staticClass:"flex flex-col h-full w-1/2 mx-2 border rounded-lg shadow-sm"},[_c('span',{staticClass:"w-full text-center font-bold bg-gray-100 mb-1"},[_vm._v("Choices")]),_c('drop-list',{staticClass:"h-full",attrs:{"items":_vm.items1,"mode":"cut"},on:{"reorder":function($event){return $event.apply(_vm.items1)},"insert":_vm.insert1},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var reorder = ref.reorder;
return [_c('drag',{key:item.text,attrs:{"data":item},on:{"cut":function($event){return _vm.remove(_vm.items1, item)}}},[_c('div',{style:(reorder
                ? {
                    borderLeft: '5px solid #1976D2'
                  }
                : {})},[_c('div',[_c('div',{staticClass:"bg-primary text-gray-200 my-1 mx-3 p-1 text-center shadow-sm"},[_vm._v(" "+_vm._s(item.text)+" ")])])])])]}},{key:"feedback",fn:function(ref){
                var data = ref.data;
return [_c('div',{key:data.text,staticClass:"bg-primary text-gray-200 p-1 m-1 text-center"},[_vm._v(" "+_vm._s(data.text)+" ")])]}}])})],1),_c('div',{staticClass:"flex flex-col h-full items-center justify-around"},[_c('feather-icon',{attrs:{"icon":"ArrowRightIcon"}}),_c('feather-icon',{attrs:{"icon":"ArrowRightIcon"}}),_c('feather-icon',{attrs:{"icon":"ArrowRightIcon"}})],1),_c('div',{staticClass:"flex flex-col h-full w-1/2 mx-2 border shadow-sm rounded-lg"},[_c('span',{staticClass:"w-full font-bold text-center bg-gray-100 mb-1"},[_vm._v("Selected")]),_c('drop-list',{staticClass:"h-full",attrs:{"items":_vm.items2,"mode":"cut"},on:{"reorder":function($event){return $event.apply(_vm.items2)},"insert":_vm.insert2},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                var item = ref.item;
                var reorder = ref.reorder;
return [_c('drag',{key:item.text,attrs:{"data":item},on:{"cut":function($event){return _vm.remove(_vm.items2, item)}}},[_c('div',{style:(reorder
                ? {
                    borderLeft: '5px solid',
                    marginLeft: '-2px'
                  }
                : {})},[_c('div',[_c('div',{staticClass:"bg-success text-gray-200 m my-1 mx-3 p-1 text-center shadow-sm"},[_vm._v(" "+_vm._s(item.text)+" ")])])])])]}},{key:"feedback",fn:function(ref){
                var data = ref.data;
return [_c('div',{key:data.text,staticClass:"bg-success text-gray-200 p-1 m-1 text-center"},[_vm._v(" "+_vm._s(data.text)+" ")])]}}])},[_vm._t("default")],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }