
<template>
  <export-overlay
    :busy="exportBusy"
    :busy-message="overlayMessage()"
    opacity="1"
    class="rounded-lg"
  >
    <div class="flex flex-col w-full mb-3 xl:flex-row">
      <link
        href="https://cdn.jsdelivr.net/npm/animate.css@3.5.1"
        rel="stylesheet"
        type="text/css"
      />
      <div
        class="flex flex-col h-full xl:w-3/12 lg:px-3 lg:flex-row xl:flex-col"
      >
        <div class="px-1 rounded-t-lg bg-secondary">
          <div class="text-3xl font-bold text-gray-200">Finalize Export</div>
        </div>
        <!-- FIRST SELECTION-->
        <div
          v-if="!claims"
          class="
            flex flex-col
            w-full
            mb-1
            border
            rounded-lg
            lg:w-1/3
            xl:w-full
            justify-content-between
          "
        >
          <div class="h-16 px-1 text-center bg-gray-100 flex-column">
            <h5 class="font-bold">
              Remove Defendants from mailing if they were...
            </h5>
          </div>
          <div class="p-1">
            <div>
              <b-form-group class="mt-1 mb-2 ml-2">
                <b-form-radio-group
                  id="radio-group-1"
                  v-model="localPackedFilters.duplicates.value"
                  :options="removalDuplicateOptions"
                  name="radios-stacked-duplicates"
                  @change="updateDuplicateQuery()"
                />
              </b-form-group>
            </div>
            <div
              v-if="localPackedFilters.duplicates.value === 'dates'"
              class="h-full"
            >
              <div>
                <label :for="`from-input-removal`" class="font-bold"
                  >From</label
                >
                <b-input-group class="mb-1">
                  <b-form-input
                    :id="`from-input-removal`"
                    v-model="removalDate.from"
                    type="text"
                    placeholder="YYYY-MM-DD"
                    autocomplete="off"
                    show-decade-nav
                    @change="updateQuery()"
                  />
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="removalDate.from"
                      show-decade-nav
                      button-only
                      right
                      locale="en-US"
                      :aria-controls="`from-input-removal`"
                      @context="onContext"
                      @change="updateQuery()"
                    />
                  </b-input-group-append>
                </b-input-group>
              </div>

              <div class="">
                <label :for="`to-input-removal`" class="font-bold">To</label>
                <b-input-group class="mb-1">
                  <b-form-input
                    :id="`to-input-removal`"
                    v-model="removalDate.to"
                    type="text"
                    placeholder="YYYY-MM-DD"
                    autocomplete="off"
                    show-decade-nav
                    @change="updateQuery()"
                  />
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="removalDate.to"
                      show-decade-nav
                      button-only
                      right
                      locale="en-US"
                      :aria-controls="`to-input-removal`"
                      @context="onContext"
                      @change="updateQuery()"
                    />
                  </b-input-group-append>
                </b-input-group>
              </div>
            </div>
            <small>
              <span class="font-bold text-red-500">ALERT: </span>
              <span class="text-muted"
                >We suggest keeping the
                <span class="font-bold">default setting</span
                ><span class="italic"> (In any mailing of the same type )</span
                >. Based on our data, the overwhelming majority of successful
                client conversions received
                <span class="font-bold">more than one letter</span>. Allowing
                our filters to handle this will provide<span class="font-bold">
                  maximum effectiveness at the lowest cost</span
                >.</span
              >
            </small>
          </div>
        </div>
        <!-- SECOND SELECTION-->
        <div
          class="
            flex flex-col
            w-full
            mb-1
            border
            rounded-lg
            lg:w-1/3
            xl:w-full
            justify-content-between
          "
        >
          <div class="h-16 bg-gray-100">
            <div class="px-1 text-center w-100">
              <h5 v-if="!claims" class="font-bold">
                What should we do when a case has multiple defendants with the
                same address?
              </h5>
              <h5 v-else class="font-bold">How many exports per creditor?</h5>
            </div>
          </div>
          <div class="p-1">
            <b-form-group class="mt-1 mb-2 ml-2">
              <b-form-radio-group
                id="radio-group-2"
                v-model="localPackedFilters.addresses.value"
                :options="removalAddressOptions"
                name="radios-stacked-addresses"
                @change="updateQuery()"
              />
            </b-form-group>
            <div v-if="claims">
              <label for="customAmount">Custom Amount:</label>
              <b-form-input
                id="customAmount"
                v-model="customAmount"
                type="number"
                @blur="updateCustomAmount"
              ></b-form-input>
            </div>
            <small class="text-muted"
              >Please Note: This includes exports history for all users.</small
            >
          </div>
        </div>
        <!-- THIRD SELECTION-->
        <div v-if="claims"
          class="
            flex flex-col
            w-full
            mb-1
            border
            rounded-lg
            lg:w-1/3
            xl:w-full
            justify-content-between
          "
        >
          <div  v-if="claims" class="h-16 bg-gray-100">
            <div class="px-1 w-100 justify-content-between">
              <h5 class="font-bold text-center">
                Creditor Size / Type
              </h5>
              <!-- <h5 v-else class="font-bold text-center">
                Should we export tracked cases?
              </h5> -->
            </div>
          </div>
          <div  class="p-1">
            <b-form-group class="mt-1 mb-2 ml-2">
              <b-form-radio-group
                id="radio-group-3"
                v-model="localPackedFilters.tracked.value"
                :options="removalTrackedOptions"
                name="radios-stacked-tracked"
                @change="updateQuery()"
              />
            </b-form-group>
            <small v-if="claims" class="text-muted"
              >This will remove all state, local, and federal creditors.</small
            >
            <small v-else class="text-muted"
              >Please Note: This will only apply to your tracked cases.</small
            >
          </div>
        </div>
        <!-- THIRD SECTION -->
        <!-- TODO: ADD A LIMIT FEATURE TO LIMIT THE NUMBER OF EXPORTS-->
        <!-- TODO: FINISH BACKEND FOR EXPORTS AND UNIQUE DOWNLOAD NAME -->
        <div
          class="w-full mb-1 border rounded-lg bg-secondary lg:w-1/3 xl:w-full"
        >
          <div class="">
            <div class="w-100 justify-content-between">
              <h5 class="text-xl font-bold text-center text-gray-200">
                FINALIZE EXPORT
              </h5>
            </div>
          </div>
          <div class="px-2 pb-2">
            <div class="flex flex-col items-center">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="primary"
                class="flex flex-row justify-center w-64 mt-2"
                @click="showModal = true"
              >
                <div class="a">
                  <feather-icon icon="DownloadIcon" class="mr-50" />
                </div>
                <span class="align-middle">Finalize</span>
              </b-button>
            </div>
          </div>
        </div>
      </div>

      <export-overlay
        :busy="busy"
        :busy-message="busyMessage"
        opacity="0.8"
        class="rounded-lg xl:w-9/12"
      >
        <!-- <div v-if="!expanded">
          <b-tabs content-class="mt-3">
            <div
              v-for="(item, index) in initialResults"
              :key="item.key"
              class="w-full"
            >
              <b-tab title="First" active
                ><p>I'm the first tab</p>
                <export-table
                  :ref="item.key"
                  :response-data="item"
                  :table-name="item.key"
                  :row-index="index"
                  :initial-load="true"
                  :packed-filters="localPackedFilters"
                  @expand="expandExportTable"
                  v-on="$listeners"
                />
              </b-tab>
            </div>
          </b-tabs>
        </div> -->
        <div class="border rounded-lg">
          <b-tabs class="w-full rounded-lg" pills card>
            <div
              v-for="(item, index) in initialResults"
              :key="item.key"
              class="w-full"
            >
              <b-tab
                :title="tabName(item.key)"
                :active="index === 1 ? true : false"
              >
                <export-table
                  :ref="item.key"
                  :response-data="item"
                  :expanded="true"
                  :table-name="item.key"
                  :row-index="index"
                  :initial-load="false"
                  :claims="claims"
                  :packed-filters="localPackedFilters"
                  @expand="expandExportTable"
                  @contract="contractExportTable()"
                  @setNameUpdates="setNameUpdates"
                  v-on="$listeners"
                />
              </b-tab>
            </div>
          </b-tabs>
        </div>

        <!-- <div >
        <export-table
          :ref="eTableName"
          :expanded="expanded"
          :response-data="eResponseData"
          :table-name="eTableName"
          :row-index="eRowIndex"
          :initial-load="false"
          :packed-filters="localPackedFilters"
          @contract="contractExportTable()"
          v-on="$listeners"
        />
      </div> -->
      </export-overlay>
      <tab-six
        :results="initialResults"
        :show-modal="showModal"
        :filename="fileName"
        :file-name-exists="fileNameExists"
        :county-counts="countyCounts"
        @genExport="genExport"
        @closeTabSixModal="closeTabSix"
        @updateFileName="updateFileName"
        @setNameUpdates="setNameUpdates"
      ></tab-six>
    </div>
  </export-overlay>
</template>

<script>
import {
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BFormGroup,
  BFormRadioGroup,
  BButton,
  BTab,
  BTabs,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ToastificationContent3 from "@core/components/toastification/ToastificationContent3.vue";
import TabSix from "./TabSix.vue";
import ExportTable from "./ExportTable.vue";
import moment from "moment";
import ExportOverlay from "./ExportOverlay";

import Ripple from "vue-ripple-directive";

export default {
  name: "ExportFinalize",

  components: {
    BTab,
    BTabs,
    TabSix,
    ExportOverlay,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BFormGroup,
    BFormRadioGroup,
    ExportTable,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    claims: {
      type: Boolean,
      required: true,
    },
    initialResults: {
      type: Array,
      required: true,
    },
    packedFilters: {
      type: Object,
      required: true,
    },
    countyCounts: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      //calTotal: {},
      //limit: this.createLimit(),
      //limitTotal: this.updateLimitTotal(this.limit),
      //value: "",
      //mailedOptions: [
      //  { text: "Yes", value: true },
      //  { text: "No", value: false }
      //],
      //mailed: true,
      //mailDate: this.getPlaceDate(),
      customAmount: "",
      nameUpdates: {},
      templates: {},
      links: false,
      exportBusy: false,
      showModal: false,
      fileNameExists: false,
      // EXPANDED TABLE VARS
      fileName: "",
      expanded: false,
      ePackedFilters: {},
      eRowIndex: 0,
      eResponseData: {},
      eTableName: "",
      eInitialLoad: false,
      // OVERLAY VARS
      busy: false,
      busyMessage: "Updating",
      removalDate: { to: this.to(), from: this.from() },
      removeDuplicates: "none",
      removalDuplicateOptions: [
        { text: "In any previous mailing", value: "any" },
        { text: "In any mailing of the same type", value: "type" },
        { text: "Previously mailed between dates", value: "dates" },
        { text: "(None) Keep all", value: "none" },
      ],
      removeAddresses: "combined",

      removeTracked: "SMALL",
      removalTrackedOptions: [
        { text: "Small", value: "SMALL" },
        { text: "Medium", value: "MEDIUM" },
        { text: "Large", value: "LARGE" },
        { text: "Government", value: "GOVERNMENT" },
      ],
    };
  },

  computed: {
    //items() {
    //
    //},
    removalAddressOptions() {
      if (this.claims) {
        return [
          { text: "All", value: "all" },
          { text: "10", value: "10" },
          { text: "100", value: "100" },
        ];
      }
      return [
        { text: "Combine Defendants", value: "combined" },
        { text: "Export Defendants Separately", value: "separately" },
      ];
    },
    formatMailedDate() {
      if (!this.mailDate || !this.mailed) {
        return null;
      }
      return this.updateDates();
    },
    localPackedFilters() {
      return this.packedFilters;
    },
  },
  watch: {
    removalDate: {
      handler(val) {
        this.removalDate = val;
        this.localPackedFilters.duplicates.date = val;
        this.updateQuery();
      },
      deep: true,
    },
  },
  async created() {
    this.localPackedFilters.duplicates.value = "type";
    this.generateFileName();
  },
  methods: {
    updateCustomAmount() {
      this.localPackedFilters.addresses.value = this.customAmount;
      this.updateQuery();
    },
    overlayMessage() {
      if (this.$can("post", "fullService")) {
        return "Finalizing Order";
      }
      return "Downloading Export File";
    },
    setNameUpdates(newData) {
      this.nameUpdates[newData.unique_id] = newData.newName;
    },
    tabName(tab) {
      if (tab === "garnish") {
        tab = "garnishments";
      }

      if (tab === "hearing") {
        tab = "hearings";
      }

      return tab.charAt(0).toUpperCase() + tab.slice(1);
    },
    genExport(data) {
      var fullService = data.fullService;
      this.localPackedFilters.limit = data.limits;
      this.localPackedFilters.mailed = data.mailed;
      this.links = data.links;
      this.templates = data.templates;
      this.showModal = false;
      this.generateExport(fullService);
    },
    closeTabSix() {
      this.showModal = false;
    },
    updateFileName(newName) {
      this.fileName = newName;
    },

    toStr(data) {
      return `${data}`;
    },
    capFirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getPlaceDate() {
      var date = new Date();
      return moment(date).format("YYYY-MM-DD");
    },
    updateDates() {
      return this.mailDate;
    },
    async generateFileName() {
      var l1 = this;
      l1.busy = true;
      const date = new Date();
      var month = date.getMonth() + 1;
      month = month.toString();
      var day = date.getDate().toString();
      var year = date.getFullYear().toString();
      var hour = date.getHours().toString();
      var min = date.getMinutes().toString();
      var second = date.getSeconds().toString();

      if (day.length < 2) {
        day = `0${day}`;
      }
      if (month.length < 2) {
        month = `0${month}`;
      }
      if (min.length < 2) {
        min = `0${min}`;
      }
      if (hour.length < 2) {
        hour = `0${hour}`;
      }
      if (second.length < 2) {
        second = `0${second}`;
      }

      const dateTime = `${month}_${day}_${year}__${hour}:${min}:${second}`;

      await l1.$AuthAxios.instance
        .get("/api-access/exports/get-filename/", {
          params: {
            dateTime: dateTime,
          },
        })
        .then(
          await function (res) {
            l1.fileName = res.data.filename;
            l1.busy = false;
          }
        )
        .catch((error) => {
          l1.busy = false;
          console.log(error);
        });
    },
    contractExportTable() {
      this.expanded = false;
      this.$emit("removeClose", false);
    },
    expandExportTable(data) {
      this.eTableName = data.four;
      this.expanded = true;
      this.$emit("removeClose", true);
    },
    // THIS FUNCTION ALLOWS THE @CHANGE BE IGNORED IF A DATE IS CHOSEN
    updateDuplicateQuery() {
      if (this.localPackedFilters.duplicates.value !== "dates") {
        return this.updateQuery();
      }
    },
    async generateExport(fullService = null) {
      const l1 = this;

      l1.exportBusy = true;
      l1.fileNameExists = false;

      var date = new Date();
      var nowDate = moment(date).format("YYYY-MM-DD");

      await l1.$emit("UpdateInitialQueryset", {
        responseData: l1.initialResults,
        packedFilters: l1.localPackedFilters,
        countyCounts: l1.countyCounts,
      });

      l1.localPackedFilters["stage"] = { key: 1, value: false };
      var urlPath = "/api-access/exports/generate-export/";
      if (l1.claims) {
        urlPath = "/api-access/exports/generate-claims-export/";
      }
      //debugger;
      await l1.$AuthAxios.instance
        .get(
          urlPath,
          {
            params: {
              initialGenerate: true,
              filters: l1.localPackedFilters,
              filename: l1.fileName,
              mailDate: nowDate,
              links: l1.links,
              templates: l1.templates,
              nameUpdates: l1.nameUpdates,
            },
          }
          // {
          //   responseType: "arraybuffer",
          //   headers: {
          //     "Content-Type": "multipart/form-data",
          //   },
          // }
        )
        .then(
          await function () {
            l1.busy = false;

            if (!fullService) {
              l1.$router.push({
                name: "export-history",
                params: { export: l1.fileName },
              });
              l1.$store.dispatch(
                "notifications/showDownloadNotification",
                true
              );
            } else {
              const toaster = "b-toaster-bottom-full";
              l1.$toast({
                component: ToastificationContent,
                props: {
                  title:
                    "Your order has been finalized. Please check your email for a finalized invoice.",
                  toaster,
                  solid: true,
                  icon: "SendIcon",
                  variant: "success",
                  //"Your export name has already been used. Choose a new name."
                },
              });
              l1.$router.push({
                name: "home-main",
                // params: { export: l1.fileName },
              });
            }

            //function str2bytes(str) {
            //  var bytes = new Uint8Array(str.length);
            //  for (var i = 0; i < str.length; i++) {
            //    bytes[i] = str[i];
            //  }
            //  return bytes;
            //}
            // l1.generateFileName();
            // var fileURL = window.URL.createObjectURL(
            //   new Blob([str2bytes(res.data.data)], { type: "application/zip" })
            // );
            // var fileLink = document.createElement("a");
            //
            // fileLink.href = fileURL;
            // fileLink.setAttribute(
            //   "download",
            //   `${res.config.params.filename}.zip`
            // );
            // document.body.appendChild(fileLink);
            //
            // fileLink.click();
          }
        )
        .catch((error) => {
          if (error.response.status === 406) {
            l1.busy = false;
            l1.exportBusy = false;
            l1.showModal = true;
            l1.$toast(
              {
                component: ToastificationContent3,
                props: {
                  errorData: error.response.data,
                  //"Your export name has already been used. Choose a new name."
                },
              },
              {
                timeout: false,
              }
            );
          }

          if (error.response.data === "File Name Already Exists") {
            const toaster = "b-toaster-bottom-full";
            l1.$toast({
              component: ToastificationContent,
              props: {
                title: "File Name Already Exists",
                toaster,
                solid: true,
                icon: "EditIcon",
                variant: "warning",
                //"Your export name has already been used. Choose a new name."
              },
            });
            l1.fileNameExists = true;
          }

          l1.busy = false;
          console.log(error);
        });
    },
    async updateQuery() {
      const l1 = this;
      l1.busy = true;

      await l1.$emit("UpdateInitialQueryset", {
        responseData: l1.initialResults,
        packedFilters: l1.localPackedFilters,
        countyCounts: l1.countyCounts,
      });

      l1.localPackedFilters["stage"] = { key: 1, value: false };

      await l1.$AuthAxios.instance
        .get("/api-access/exports/filter-initial-list/", {
          params: {
            filters: l1.localPackedFilters,
          },
        })
        .then(
          await function (res) {
            l1.busy = false;
            l1.$emit("UpdateInitialQueryset", {
              responseData: res.data,
              packedFilters: l1.localPackedFilters,
              countyCounts: res.data[0].county_counts,
            });
          }
        )
        .catch((error) => {
          l1.busy = false;
          console.log(error);
        });
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no - date - selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD;
    },
    to() {
      //var dateOffset = 24 * 60 * 60 * 1000 * 30
      var myDate = new Date();
      //myDate.setTime(myDate.getTime() - dateOffset)
      return moment(myDate).format("YYYY-MM-DD");
    },
    from() {
      var dateOffset = 24 * 60 * 60 * 1000 * 30;
      var myDate = new Date();
      myDate.setTime(myDate.getTime() - dateOffset);
      return moment(myDate).format("YYYY-MM-DD");
    },
  },
};
</script>


