var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"\n    flex\n    items-center\n    justify-center\n    w-full\n    h-full\n    p-1\n    rounded-lg\n    lg:p-10\n  "},[_c('div',{staticClass:"max-w-6xl p-1 xl:min-w-6xl"},[_c('export-overlay',{attrs:{"busy":_vm.busy}},[_c('form-wizard',{staticClass:"steps-transparent",attrs:{"color":"#7367F0","title":null,"subtitle":null,"finish-button-text":"Submit","back-button-text":"Previous"},on:{"on-complete":_vm.formSubmitted}},[_c('tab-content',{attrs:{"title":"Location & Case Type","icon":"feather icon-map-pin","before-change":_vm.validationTabTwo}},[_c('tab-two',{ref:"valTabTwo",attrs:{"choices":_vm.countyChoices,"case-type-choices":_vm.caseTypeChoices,"state-choices":_vm.stateChoices},on:{"eventCounty":_vm.updateCounty,"eventCaseType":_vm.updateCaseType,"selectedExportTypeEvent":_vm.updateExportType,"eventState":_vm.updateState,"eventCourt":_vm.updateCourt}})],1),(_vm.selectedExportType != 'Claims Marketing')?_c('tab-content',{attrs:{"title":"Mailing Type","icon":"feather icon-mail","before-change":_vm.validationTabThree}},[_c('tab-three',{ref:"valTabThree",attrs:{"case-type":_vm.caseType},on:{"eventMailingType":_vm.updateMailingType}})],1):_vm._e(),(_vm.$can('post', 'fixedFullService'))?_c('tab-content',{attrs:{"before-change":_vm.validationTabFour,"title":"Filters","icon":"feather icon-filter"}},[_c('tab-four',{ref:"valTabFour",attrs:{"stages":_vm.mailingType,"claims":_vm.claims,"amounts":_vm.amounts,"tab-data":{
              counties: _vm.counties,
              caseType: _vm.caseType,
              state: _vm.selectedStates,
              courts: _vm.selectedCourt,
              exportType: _vm.selectedExportType,
              mailingType: _vm.mailingType,
            }}})],1):_c('tab-content',{attrs:{"title":"Filters","icon":"feather icon-filter"}},[_c('tab-four',{ref:"valTabFour",attrs:{"stages":_vm.mailingType,"claims":_vm.claims,"amounts":_vm.amounts,"tab-data":{
              counties: _vm.counties,
              caseType: _vm.caseType,
              state: _vm.selectedStates,
              courts: _vm.selectedCourt,
              mailingType: _vm.mailingType,
              exportType: _vm.selectedExportType,
            }}})],1),(!_vm.$can('post', 'fixedFullService'))?_c('tab-content',{attrs:{"title":"Service Type","icon":"feather icon-shopping-cart","before-change":_vm.validationTabFour}},[_c('tab-four-five',{ref:"valTabFourFive"})],1):_vm._e(),_c('tab-content',{attrs:{"title":"FINALIZE","icon":"feather icon-download"}})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }