<template>
  <div
    class="
      flex
      items-center
      justify-center
      w-full
      h-full
      p-1
      rounded-lg
      lg:p-10
    "
  >
    <div class="max-w-6xl p-1 xl:min-w-6xl">
      <export-overlay :busy="busy">
        <form-wizard
          color="#7367F0"
          :title="null"
          :subtitle="null"
          finish-button-text="Submit"
          back-button-text="Previous"
          class="steps-transparent"
          @on-complete="formSubmitted"
        >
          <!-- account detail tab 
          <tab-content title="Getting Started" icon="feather icon-file-text">
            <tab-one />
          </tab-content>

          personal details -->
          <tab-content
            title="Location & Case Type"
            icon="feather icon-map-pin"
            :before-change="validationTabTwo"
          >
            <tab-two
              ref="valTabTwo"
              :choices="countyChoices"
              :case-type-choices="caseTypeChoices"
              :state-choices="stateChoices"
              @eventCounty="updateCounty"
              @eventCaseType="updateCaseType"
              @selectedExportTypeEvent="updateExportType"
              @eventState="updateState"
              @eventCourt="updateCourt"
            />
          </tab-content>

          <!-- mailing type -->
          <tab-content
            v-if="selectedExportType != 'Claims Marketing'"
            title="Mailing Type"
            icon="feather icon-mail"
            :before-change="validationTabThree"
          >
            <tab-three
              ref="valTabThree"
              :case-type="caseType"
              @eventMailingType="updateMailingType"
            />
          </tab-content>

          <!-- social link -->

          <tab-content
            v-if="$can('post', 'fixedFullService')"
            :before-change="validationTabFour"
            title="Filters"
            icon="feather icon-filter"
          >
            <tab-four
              ref="valTabFour"
              :stages="mailingType"
              :claims="claims"
              :amounts="amounts"
              :tab-data="{
                counties: counties,
                caseType: caseType,
                state: selectedStates,
                courts: selectedCourt,
                exportType: selectedExportType,
                mailingType: mailingType,
              }"
            />
          </tab-content>

          <tab-content v-else title="Filters" icon="feather icon-filter">
            <tab-four
              ref="valTabFour"
              :stages="mailingType"
              :claims="claims"
              :amounts="amounts"
              :tab-data="{
                counties: counties,
                caseType: caseType,
                state: selectedStates,
                courts: selectedCourt,
                mailingType: mailingType,
                exportType: selectedExportType,
              }"
            />
          </tab-content>

          <tab-content
            v-if="!$can('post', 'fixedFullService')"
            title="Service Type"
            icon="feather icon-shopping-cart"
            :before-change="validationTabFour"
          >
            <tab-four-five ref="valTabFourFive" />
          </tab-content>

          <tab-content title="FINALIZE" icon="feather icon-download">
          </tab-content>
        </form-wizard>
      </export-overlay>
    </div>
  </div>
</template>

<script>
import ExportOverlay from "./ExportOverlay";

import TabTwo from "./TabTwo";
import TabThree from "./TabThree";
import TabFour from "./TabFour";
import TabFourFive from "./TabFourFive";
import { FormWizard, TabContent } from "vue-form-wizard";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { codeIconInfo } from "./code";

export default {
  name: "ExportWizard",
  components: {
    ExportOverlay,

    TabTwo,
    TabThree,
    TabFourFive,
    FormWizard,
    TabContent,
    TabFour,
    //BSpinner,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },

  data() {
    return {
      // TIMER
      claims: false,
      selectedExportType: "",
      selectedCourt: [],
      selectedStates: [],
      new: false,
      minCount: 0,
      hrCount: 0,
      time: 0,
      isRunning: false,
      interval: null,
      // TAB TWO PROPS
      counties: [],
      caseType: [],
      countyChoices: [],
      caseTypeChoices: [],
      stateChoices: [],
      // TAB  FOUR PROPS
      mailingType: [],
      amounts: {},
      /// Other Data
      busy: false,
      busyMessage: "Loading",
      selectedContry: "select_value",
      selectedLanguage: "nothing_selected",
      codeIconInfo,
      countryName: [
        { value: "select_value", text: "Select Value" },
        { value: "Russia", text: "Russia" },
        { value: "Canada", text: "Canada" },
        { value: "China", text: "China" },
        { value: "United States", text: "United States" },
        { value: "Brazil", text: "Brazil" },
        { value: "Australia", text: "Australia" },
        { value: "India", text: "India" },
      ],
      languageName: [
        { value: "nothing_selected", text: "Nothing Selected" },
        { value: "English", text: "English" },
        { value: "Chinese", text: "Mandarin Chinese" },
        { value: "Hindi", text: "Hindi" },
        { value: "Spanish", text: "Spanish" },
        { value: "Arabic", text: "Arabic" },
        { value: "Malay", text: "Malay" },
        { value: "Russian", text: "Russian" },
      ],
    };
  },
  watch: {
    busy(newValue) {
      if (!newValue) {
        this.time = 0;
        this.toggleTimer();
      } else {
        this.toggleTimer();
      }
    },
  },
  async created() {
    const l1 = this;
    l1.busy = true;
    this.time = 0;
    //this.toggleTimer()

    await this.$AuthAxios.instance
      .get("/api-access/exports/fetch-choices/")
      .then(
        await function (res) {
          l1.stateChoices = res.data.states;
          l1.amounts = res.data.amounts;
          l1.countyChoices = res.data.counties;
          l1.caseTypeChoices = res.data.case_types;
          l1.busy = false;
        }
      )
      .catch((error) => {
        l1.busy = false;
        console.log(error);
      });
  },

  methods: {
    seconds() {
      var zero = 0;

      if (this.time > 60) {
        this.time = 0;
        this.minCount += 1;
      }

      return this.time.toString().length < 2
        ? zero.toString() + this.time.toString()
        : this.time.toString();
    },
    minutes() {
      var zero = 0;
      if (this.minCount > 60) {
        this.minCount = 0;
        this.hrCount += 1;
      }
      return this.minCount.toString().length < 2
        ? zero.toString() + this.minCount.toString()
        : this.minCount.toString();
    },
    hours() {
      return this.hrCount;
    },
    toggleTimer() {
      if (this.isRunning) {
        clearInterval(this.interval);
      } else {
        this.interval = setInterval(this.incrementTime, 1000);
      }
      this.isRunning = !this.isRunning;
    },
    incrementTime() {
      this.time = parseInt(this.time) + 1;
    },
    toggleBusyOverlay(bool, message = "Loading") {
      if (bool) {
        this.busy = true;
        this.busyMessage = message;
      } else {
        this.busy = false;
        this.busyMessage = "Loading";
      }
    },
    updateMailingType(newValue) {
      var newArray = [];

      for (const value of newValue) {
        newArray.push(value.value);
      }

      this.mailingType = newArray;
    },
    updateCounty(newValue) {
      this.counties = newValue;
    },
    updateCaseType(newValue) {
      this.caseType = newValue;
    },
    updateExportType(newValue) {
      this.selectedExportType = newValue;
      this.mailingType = ["filed"];
    },
    updateState(newValue) {
      this.selectedStates = newValue;
    },
    updateCourt(newValue) {
      this.selectedCourt = newValue;

      if (newValue.length > 0) {
        this.claims = true;
      }
    },

    async validationTabTwo() {
      return new Promise((resolve, reject) => {
        this.$refs.valTabTwo.validate(resolve, reject).then(function (val) {
          if (val) {
            return resolve(true);
          } else {
            return reject();
          }
        });
      });
    },
    validationTabThree() {
      return new Promise((resolve, reject) => {
        this.$refs.valTabThree.validate(resolve, reject).then(function (val) {
          if (val) {
            return resolve(true);
          } else {
            return reject();
          }
        });
      });
    },
    validationTabFour() {
      return new Promise((resolve, reject) => {
        if (this.$can("post", "fixedFullService")) {
          this.$store.dispatch("auth/updateFullService", "option1");
        }
        this.$refs.valTabFour.validate(resolve, reject).then(function () {
          return resolve(true);
        });
      });
    },
    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Form Submitted",
          icon: "EditIcon",
          variant: "success",
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
