<template>
  <div>
    <b-modal
      id="modal-center"
      v-model="show"
      header-class="bg-gray-200 shadow-md"
      scrollable
      hide-footer
      size="lg"
    >
      <template #modal-header="{}" class="bg-gray-200">
        <!-- Emulate built in modal header close button action -->
        <h5 v-if="!$can('post', 'fullService')" class="flex flex-col text-lg">
          <span class="font-bold underline text-success">File Name:</span>
          {{ fileName }}.zip
        </h5>
        <h5 v-else class="flex flex-col text-lg">
          <span class="font-bold underline text-success">
            Complete Purchase
          </span>
        </h5>

        <b-button variant="outline-danger" @click="closeModal()">
          Close Window
        </b-button>
      </template>

      <div class="flex flex-col items-center w-full">
        <b-overlay :show="showAreYouSure">
          <template #overlay>
            <div
              ref="dialogexport"
              tabindex="-1"
              role="dialog"
              aria-modal="false"
              aria-labelledby="form-confirm-label"
              class="w-full text-center"
            >
              <div class="mb-5 text-2xl text-center underline text-danger">
                <strong id="form-confirm-label w-full">WARNING !!</strong>
              </div>
              <div class="w-full mb-5 text-xl text-center text-warning">
                <strong id="form-confirm-label w-full">
                  <h4 class="mb-5 text-gray-800">
                    When exported records are marked as
                    <span class="italic">'mailed'</span>, as you have indicated;
                    the export data will be saved under your
                    {{ $store.getters["auth/accountType"] }}'s compliance page
                    and future exports will be filtered based on these records!
                  </h4>
                  <p class="italic">
                    ** IT IS CRUCIAL THAT YOU ONLY SELECT YES IF THE RECORDS
                    GENERATED FROM THE EXPORT WILL BE MAILED.
                  </p>
                </strong>
              </div>
              <div class="">
                <b-button
                  size="lg"
                  variant="outline-danger"
                  class="mr-3"
                  @click="showAreYouSure = false"
                >
                  NO GO BACK!
                </b-button>

                <b-button
                  size="lg"
                  variant="outline-success"
                  @click="genExport()"
                  >YES</b-button
                >
              </div>
            </div>
          </template>
          <!-- <template #overlay>
        <div
          ref="dialogexport"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="w-full text-center"
        >
          <div class="mb-5 text-2xl text-center underline text-danger">
            <strong id="form-confirm-label w-full">WARNING !!</strong>
          </div>
        

          <div class="">
            <b-button
              variant="outline-danger"
              class="mr-3"
              @click="showAreYouSure = false"
            >
              Cancel
            </b-button>

            <b-button variant="outline-success">Yes</b-button>
          </div>
        </div>
      </template> -->
          <div class="flex flex-col w-full space-x-10 xl:flex-row">
            <div
              v-if="!$can('post', 'fullService')"
              class="w-full mt-2 text-lg border rounded-lg shadow-sm lg:w-1/2"
            >
              <div class="py-1 font-bold text-center bg-gray-200">
                Are you Going to Mail this Export?
              </div>
              <div class="flex flex-col items-center justify-around h-64">
                <b-form-group class="mt-1">
                  <b-form-radio-group
                    v-model="mailed"
                    :options="mailedOptions"
                    :name="`radios-stacked-mailed`"
                  />
                </b-form-group>
                <div v-if="mailed" class="h-full text-center">
                  <label class="w-full ml-1 text-left">When?</label>
                  <b-input-group class="mb-1">
                    <b-form-input
                      id="mailed"
                      v-model="mailDate"
                      type="text"
                      placeholder="YYYY-MM-DD"
                      autocomplete="off"
                      show-decade-nav
                    />
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="mailDate"
                        show-decade-nav
                        button-only
                        right
                        locale="en-US"
                        aria-controls="mailed"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </div>
                <div v-else class="h-full text-center"></div>
                <div class="w-full mb-1 ml-2">
                  <div class="text-xs font-bold text-danger">
                    ** PLEASE NOTE : COMPLIANCE RECORDS AND ALL FUTURE EXPORTS
                    WILL BE FILTERED BASED ON THIS MAILING DATE. PLEASE CHOOSE
                    DATE APPROPRATELY!
                  </div>
                </div>
              </div>
            </div>

            <div v-else class="mb-10">
              <b-row class="font-bold text-lg mt-10">
                <b-col> Price Summary </b-col>
              </b-row>
              <b-container fluid class="text-lg border rounded-lg shadow-sm">
                <b-row>
                  <b-table
                    ref="priceTable"
                    class="border-b-default border-gray-300 text-sm"
                    :items="summaryItems"
                  />
                </b-row>
                <b-row class="mb-5 mt-4">
                  <b-col class="text-center text-lg text-success">
                    <span class="font-bold">Shipped {{ whenShipped() }}</span>
                    if purchased before:
                    <span class="font-bold underline"
                      >11:59 AM
                      {{
                        whenShipped() === "Today"
                          ? ""
                          : whenShipped().toLowerCase()
                      }}</span
                    >
                    | <span class="text-xs">**</span
                    ><span class="italic text-xl underline">Guaranteed</span>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="invisible" cols="4"> . </b-col>
                  <b-col class="text-xs break-words text-right" cols="8">
                    * By clicking "Purchase & Schedule", you agree to have your
                    account automatically charged in the amount up to the price
                    listed above, plus tax. <br />
                    ** USPS holidays not included.
                  </b-col>
                </b-row>
                <b-row
                  :class="
                    !$store.getters['auth/envelopeId'] ? 'bg-gray-300' : ''
                  "
                >
                  <b-col
                    :class="`flex flex-col items-center justify-center text-center ${
                      !$store.getters['auth/envelopeId']
                        ? 'text-danger text-xs'
                        : 'invisible'
                    }`"
                    cols="8"
                    >{{
                      !$store.getters["auth/envelopeId"]
                        ? "** missing envelope template - contact Public Digital to continue **"
                        : false
                    }}</b-col
                  >
                  <b-col class="my-1" cols="4">
                    <b-button
                      v-if="mailed"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      :variant="
                        !$store.getters['auth/envelopeId']
                          ? 'secondary'
                          : 'primary'
                      "
                      :disabled="
                        !$store.getters['auth/envelopeId'] ? true : false
                      "
                      class="flex flex-row justify-center w-64 mr-5"
                      @click="scheduleMailing()"
                    >
                      <div class="a">
                        <feather-icon icon="CreditCardIcon" class="mr-50" />
                      </div>
                      <span
                        :class="`align-middle ${
                          !$store.getters['auth/envelopeId']
                            ? 'line-through'
                            : ''
                        }`"
                        >Purchase & Schedule</span
                      >
                    </b-button>
                  </b-col>
                  <div class="b-col"></div>
                </b-row>
              </b-container>
            </div>
            <div
              v-if="!$can('post', 'fullService')"
              class="
                w-full
                mt-2
                text-lg
                border
                rounded-lg
                shadow-sm
                bg-secondary
                lg:w-1/2
              "
            >
              <div class="">
                <div class="py-1 font-bold text-center text-gray-200">
                  DOWNLOAD EXPORT
                </div>
              </div>
              <div class="px-2 pb-2">
                <label for="filename" class="text-gray-100"
                  >Filename<span
                    v-if="fileNameExists"
                    class="px-1 ml-1 text-red-700 bg-gray-600 text-bold"
                  >
                    Name Already Exists
                  </span></label
                >
                <div class="flex flex-col items-center">
                  <b-input-group append=".zip">
                    <b-form-input
                      id="filename"
                      v-model="fileName"
                      debounce="1000"
                      placeholder="Please Choose a File Name"
                      @change="updateFileName"
                    />
                  </b-input-group>
                  <b-button
                    v-if="mailed"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="primary"
                    class="flex flex-row justify-center w-64 mt-2"
                    @click="areYouSure()"
                  >
                    <div class="a">
                      <feather-icon icon="DownloadIcon" class="mr-50" />
                    </div>
                    <span class="align-middle">EXPORT MAILING</span>
                  </b-button>
                  <b-button
                    v-else
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="primary"
                    class="flex flex-row justify-center w-64 mt-2"
                    @click="genExport()"
                  >
                    <div class="a">
                      <feather-icon icon="DownloadIcon" class="mr-50" />
                    </div>
                    <span class="align-middle">EXPORT</span>
                  </b-button>
                  <p class="mt-2 text-gray-200">
                    {{ mailed ? "Mailing" : "Export" }} Contains<span
                      class="font-bold"
                    >
                      {{ limitTotal }}</span
                    >
                    Records.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="w-full mt-2 text-lg font-bold">Final Totals</div>
        </b-overlay>
        <div class="w-full text-lg border rounded-lg shadow-sm">
          <div class="flex flex-col items-center">
            <b-table striped hover fixed :items="tableData" foot-clone>
              <template #cell(Type)="data">
                <div class="font-bold underline">{{ data.item.Type }}</div>
              </template>
              <template #cell(Limit_Export)="data">
                <div class="flex flex-col" style="max-width: 80px">
                  <div
                    v-if="maxLimits[data.item.Type]"
                    class="text-xs text-left text-danger"
                  >
                    <div class="underline">Max:</div>
                    {{ initialLimits[data.item.Type] }}
                  </div>
                  <div v-else class="text-xs">
                    <div class="underline">Exporting:</div>
                    {{ exportLimits[data.item.Type] }} records
                  </div>
                  <b-form-input
                    v-model="exportLimits[data.item.Type]"
                    type="text"
                    debounce="500"
                    :placeholder="toStr(data.item.Limit_Export)"
                    size="sm"
                  ></b-form-input>
                </div>
              </template>
              <template #foot(Type)> Totals</template>

              <!-- Default fall-back custom formatted footer cell -->
              <template #foot(Total)>
                <div class="font-bold">{{ colTotals.total }}</div>
              </template>
              <template #foot(Mailed)>
                <div class="font-bold">{{ colTotals.mail }}</div>
              </template>
              <template #foot(Shared)>
                <div class="font-bold">{{ colTotals.share }}</div>
              </template>
              <template #foot(Tracked)>
                <div class="font-bold">{{ colTotals.track }}</div>
              </template>
              <template #foot(Limit_Export)>
                <div class="font-bold">{{ limitTotal }}</div>
              </template>
            </b-table>
            <div
              class="
                border
                rounded-lg
                flex flex-col
                items-center
                justify-center
                bg-gray-100
                mt-3
              "
            >
              <div class="text-xl text-center mt-1 font-bold">Custom Links</div>
              <div class="text-center" style="max-width: 550px">
                Do you want to include custom defendant links that allow mail
                recipients to view their case data?
              </div>
              <div v-if="linkError" class="text-red-500">
                ---- Please Make a Selection ----
              </div>
              <b-form-group class="mt-1">
                <b-form-radio-group
                  v-model="links"
                  :state="linkError"
                  :options="linkOptions"
                  :name="`radios-stacked-links`"
                />
              </b-form-group>
            </div>

            <b-col v-if="templateError" cols="12" class="text-red-700 my-2">
              <b-alert variant="warning" show>
                <div class="alert-body">
                  Please select a template for each mailing!
                </div>
              </b-alert>
            </b-col>
            <b-col v-else cols="12" class="my-2 invisible">
              <b-alert variant="warning" show>
                <div class="alert-body">
                  Please select a template for each mailing!
                </div>
              </b-alert>
            </b-col>
            <templates
              :mail-export="true"
              :mail-types="mailingTypes"
              @updateSelectedTemplate="updateTemplates"
            />
            <p class="mt-1 ml-2">
              ** If you have chosen to mail the selected records, please note it
              will become part of your
              {{ $store.getters["auth/accountType"] }}'s compliance records.
            </p>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>



<script>
import {
  BContainer,
  BCol,
  BRow,
  BOverlay,
  BTable,
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BFormGroup,
  BFormRadioGroup,
  BButton,
  BAlert,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import moment from "moment";
import Templates from "../../public-digital/Templates.vue";
//import ExportOverlay from "./ExportOverlay"
export default {
  name: "ExportTabSix",
  components: {
    BContainer,
    BCol,
    BRow,
    BOverlay,
    BTable,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BFormGroup,
    BFormRadioGroup,
    BButton,
    Templates,
    BAlert,
  },
  directives: {
    Ripple,
  },
  props: {
    results: {
      type: Array,
      required: true,
    },
    showModal: {
      type: Boolean,
      required: true,
    },
    fileNameExists: {
      type: Boolean,
      required: true,
    },
    filename: {
      type: String,
      required: true,
    },
    countyCounts: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      templates: {},
      templateError: false,
      // WATCHED VARIABLES
      //fileName: "",
      // MUTATED VARIABLES
      linkError: null,
      links: null,
      linkOptions: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
      show: this.showModal,
      exportLimits: this.generateExportLimits(this.results),
      initialLimits: this.generateExportLimits(this.results),
      preMutateLimits: {
        Garnish: 0,
        Disposed: 0,
        Hearing: 0,
        Served: 0,
        Filed: 0,
      },
      maxLimits: {
        Garnish: false,
        Disposed: false,
        Hearing: false,
        Served: false,
        Filed: false,
      },
      //tableData: [],
      //colTotals: {},

      // VARIABLES
      showAreYouSure: false,
      mailed: true,
      mailDate: this.getPlaceDate(),
      // SELECTIONS
      modLimit: {
        Garnish: "0",
        Disposed: "0",
        Hearing: "0",
        Served: "0",
        Filed: "0",
      },
      mailedOptions: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
    };
  },

  computed: {
    mailingTypes() {
      var mailingArray = [];
      this.results.forEach((element) => {
        mailingArray.push(element.key);
      });

      return mailingArray;
    },
    limitTotal() {
      var loadCheck = this.exportLimits ? true : false;
      var total = 0;
      if (loadCheck) {
        for (const key of Object.keys(this.exportLimits)) {
          total += this.toIn(this.exportLimits[key]);
        }
      }
      return total;
    },
    tableData() {
      return this.genTableData(this.results);
    },
    colTotals() {
      return this.calColTotals(this.results);
    },

    fileName: {
      get() {
        return this.filename;
      },
      set(newValue) {
        this.$emit("updateFileName", newValue);
      },
    },
  },
  watch: {
    showModal(newValue) {
      this.show = newValue;
    },
    exportLimits: {
      handler(newValue) {
        if (typeof this.$refs.priceTable !== 'undefined') {
          this.$refs.priceTable.refresh();
        }
        for (const key of Object.keys(newValue)) {
          var newNum = this.toIn(newValue[key].replace(/\D/g, ""));
          var initNum = this.toIn(this.initialLimits[key]);
          var curNum = this.toIn(this.preMutateLimits[key]);

          if (newValue[key] === "") {
            this.exportLimits[key] = "0";
            this.preMutateLimits[key] = "0";
            this.maxLimits[key] = false;
          } else if (newNum > initNum) {
            this.exportLimits[key] = this.initialLimits[key];
            this.preMutateLimits[key] = this.initialLimits[key];
            this.maxLimits[key] = true;
          } else if (newNum < curNum) {
            this.exportLimits[key] = this.toStr(newNum);
            this.preMutateLimits[key] = this.initialLimits[key];
            this.maxLimits[key] = false;
          }
        }
      },
      deep: true,
    },
    results: {
      // eslint-disable-next-line no-unused-vars
      async handler(newValue, oldValue) {
        this.initialLimits = await this.generateExportLimits(
          this.results,
          oldValue
        );
        this.exportLimits = this.generateExportLimits(this.results, oldValue);
      },
      deep: true,
    },
  },

  methods: {
    whenShipped() {
      const d = new Date();
      let day = d.getDay();
      let hour = d.getHours();

      if ((day >= 5 && hour > 11) || day >= 6 || day === 0) {
        return "Monday";
      }

      if (hour > 11) {
        return "Tomorrow";
      } else {
        return "Today";
      }
    },
    summaryItems() {
      var l1 = this;
      var cost = l1.$store.getters["auth/costPerLetter"];

      // loop through countyCounts and get total for each county

      // base county variables
      var baseCountyTotals = 0;

      // shared county variables
      var totalCount = 0;
      var totalCost = 0;

      // final county array
      var allCounties = [];

      for (const [key, value] of Object.entries(l1.countyCounts)) {
        if (value.new_price) {
          totalCount += value.count;
          var tempCost = value.price * value.count;
          totalCost += tempCost;
          allCounties.push({
            qty: value.count,
            item_name: "1-Piece Insert - Letter USPS Mailed" + " (" + key + ")",
            rate: "$" + l1.currencyFormat(value.price),
            total: `$${l1.currencyFormat(tempCost)} est.`,
          });
        } else {
          baseCountyTotals += value.count;
          totalCount += value.count;
          totalCost += cost * value.count;
        }
      }

      allCounties.push({
        qty: baseCountyTotals,
        item_name: "1-Piece Insert - Letter USPS Mailed (All other Counties)",
        rate: "$" + l1.currencyFormat(cost),
        total: `$${l1.currencyFormat(cost * baseCountyTotals)} est.`,
      });

      allCounties.push({
        qty: totalCount,
        item_name: "",
        rate: "*ESTIMATED TOTAL MAILING PRICE",
        total: `$${l1.currencyFormat(totalCost)}`,
      });

      return allCounties;
    },
    currencyFormat(v) {
      return v.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");

      //return `$${("" + v).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    },
    mailingPrice() {
      return this.$store.getters["auth/costPerLetter"];
    },
    updateTemplates(templates) {
      this.templates = templates;
    },
    // REGULAR METHODS
    areYouSure() {
      var mailTypesLen = Object.keys(this.mailingTypes).length;
      var templateLen = Object.keys(this.templates).length;

      if (this.links === null) {
        this.linkError = true;
      } else if (mailTypesLen != templateLen) {
        this.templateError = true;
      } else {
        this.showAreYouSure = true;
      }
    },
    // MUTATE OBJECT GENERATORS
    generateExportLimits(val) {
      var l1 = this;
      var newObj = {};

      function createObj(value) {
        newObj[l1.capFirst(value.key)] = l1.toStr(value.count);
      }

      val.forEach(createObj);

      return newObj;
    },
    genTableData(val) {
      var items = [];

      for (const value of Object.values(val)) {
        items.push({
          Type: this.capFirst(value.key),
          Total: value.count,
          Mailed: value.stats.previous_mailing,
          Shared: value.stats.shared_addresses,
          Tracked: value.stats.total_tracked,
          Limit_Export: value.count,
        });
      }

      return items;
    },
    calColTotals(val) {
      var total = 0;
      var mail = 0;
      var share = 0;
      var track = 0;

      for (const value of Object.values(val)) {
        total += value.count;
        mail += value.stats.previous_mailing;
        share += value.stats.shared_addresses;
        track += value.stats.total_tracked;
      }

      return { total: total, mail: mail, share: share, track: track };
    },
    // FORMATTERS
    getPlaceDate() {
      var date = new Date();
      return moment(date).format("YYYY-MM-DD");
    },
    toStr(x) {
      return x.toString();
    },
    toIn(x) {
      return parseInt(x);
    },
    capFirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    lowFirst(string) {
      return string.charAt(0).toLowerCase() + string.slice(1);
    },
    // EVENTS
    scheduleMailing() {
      var mailTypesLen = Object.keys(this.mailingTypes).length;
      var templateLen = Object.keys(this.templates).length;

      if (this.links === null) {
        this.linkError = true;
      } else if (mailTypesLen != templateLen) {
        this.templateError = true;
      } else {
        this.exportLimits.mailed = true;

        this.$emit("genExport", {
          fullService: this.$can("post", "fullService"),
          mailed: { mailed: this.mailed, mailDate: this.mailDate },
          limits: this.exportLimits,
          links: this.links,
          templates: this.templates,
        });
      }
    },
    genExport() {
      this.showAreYouSure = false;
      this.exportLimits.mailed = true;

      this.$emit("genExport", {
        mailed: { mailed: this.mailed, mailDate: this.mailDate },
        limits: this.exportLimits,
        links: this.links,
        templates: this.templates,
      });
    },

    closeModal() {
      this.showAreYouSure = false;
      this.$emit("closeTabSixModal");
    },
    updateFileName() {
      this.$emit("updateFileName", this.fileName);
    },
  },
};
</script>

