
<template>
  <div class="flex flex-col items-center" style="height: 600px">
    <div class="w-full mr-4 text-sm italic font-bold text-right text-alert">
      * Please note the selection order is important.
    </div>
    <validation-observer
      ref="val"
      tag="form"
      class="flex justify-around h-full p-8 lg:flex-row"
    >
      <div
        class="flex flex-col items-center justify-center my-2 mr-1 border rounded-lg shadow-sm lg:w-3/12"
      >
        <div class="lg:w-2/3">
          <div class="text-xl font-bold lg:mb-3">
            What kind of mailing would you like to send out?
       
          </div>
          <div>
            Choose from a selection of
            <span class="italic font-semibold text-black">stages</span> that a
            potential client might be in. Don't worry about sending duplicates,
            we will take care of that in the next step.
          </div>
          <div class="mt-1">
            You can choose multiple types of mailings but in the next step you
            will need to filter each mailing type individually.
          </div>
        </div>
      </div>
      <validation-provider
        #default="{ errors }"
        ref="mailingtype"
        name="Mailing Type"
        rules="required"
        class="flex flex-col items-center justify-center h-full mr-1 lg:w-9/12"
      >
        <input
          v-model="typeSelection"
          class="hidden"
          @input="validate($event)"
        />
        <div class="flex flex-col items-center justify-center w-3/4 h-full">
          <div class="pb-4 text-2xl font-bold">
            Drag Choices to the 'Selected' Column.
          </div>

          <drag-type class="h-full"  :case-type="caseType" @typeMailingChange="typeMailingChange">
            <b-form-invalid-feedback
              class="text-center"
              :state="errors.length > 0 ? false : null"
            >
              Please select AT LEAST one mailing type !!!
            </b-form-invalid-feedback>
          </drag-type>
        </div>
        <div class="flex flex-col justify-end w-full mt-2 mb-2">
          <div class="w-full pl-20">
            <div class="ml-1 text-xs italic">
              * Defendants are only allowed to be included in one mail type per
              export.
            </div>
            <div class="ml-2 text-xs italic">
              Mail type priority will be given based on the order you choose
              above.
            </div>
          </div>
        </div>
      </validation-provider>
    </validation-observer>
  </div>
</template>

<script>
import { required } from "@validations"
import { BFormInvalidFeedback } from "bootstrap-vue"
import DragType from "./DragType.vue"

import { ValidationProvider, ValidationObserver } from "vee-validate"
export default {
  name: "LocationCaseType",
  components: {
    DragType,
    // BFormCheckboxGroup,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback
  },
  props: {
    caseType: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      typeSelection: [],

      required,

      dir: "ltr"
    }
  },
  
  methods: {
    typeMailingChange(newVal) {
      this.typeSelection = newVal
    },
    async validate() {
      var l1 = this
      var status = false
      await l1.$refs.val.validate().then(
        await function(success) {
          if (success) {
            l1.$emit("eventMailingType", l1.typeSelection)

            status = true
          } else {
            status = false
          }
        }
      )

      return status
    }
  }
}
</script>
