<template>
  <div class="flex items-center justify-center">
    <div class="w-full mb-32">
      <form-wizard-icon />
    </div>
  </div>

  <!-- 
    
    <b-row class="bg-gray-400 p-2 h-">
    <b-col cols="12">
      
       <form-wizard-number />
      <form-wizard-vertical />
      <form-wizard-vertical-icon /> 
    </b-col>
  </b-row>
  
   -->
</template>

<script>
// import FormWizardNumber from "./FormWizardNumber.vue"
import FormWizardIcon from "./FormWizardIcon.vue"
// import FormWizardVertical from "./FormWizardVertical.vue"
// import FormWizardVerticalIcon from "./FormWizardVerticalIcon.vue"

export default {
  components: {
    // FormWizardNumber,
    FormWizardIcon
    // FormWizardVertical,
    // FormWizardVerticalIcon
  }
}
</script>


