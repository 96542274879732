<template>
  <div class="toastification">
    <div class="d-flex align-items-start">
      <b-avatar variant="warning" size="1.8rem" class="mr-75 flex-shrink-0">
        <feather-icon icon="AlertTriangleIcon" size="15" />
      </b-avatar>
      <div class="d-flex flex-grow-1">
        <div>
          <h5 class="mb-0 font-weight-bolder toastification-title text-warning">
            We found some issues with your template{{
              templateCount > 1 ? "s" : ""
            }}. Please correct the errors below to proceed with your mailing
          </h5>
          <div class="d-inline-block text-body font-bold"></div>
          <div v-for="(value, key) in errorData" :key="key" class="mb-1">
            <div class="text-lg font-bold">{{ key }} Template</div>
            <hr />
            <div v-for="(fields, type) in value" :key="type">
              <div v-if="fields != [] && fields != false" class="flex flex-col">
                <div class="d-inline-block flex flex-row text-body ml-1">
                  <span class="font-bold underline"> {{ type }} fields</span> |
                  <span class="text-xs italic">
                    {{
                      type === "missing"
                        ? "these need to be added"
                        : "these need to be removed"
                    }}</span
                  >
                </div>
                <div class="d-inline-block flex flex-row text-body mb-1 ml-2">
                  {{ fields }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <span
          class="cursor-pointer toastification-close-icon ml-auto"
          @click="$emit('close-toast')"
        >
          <feather-icon icon="XIcon" class="text-body" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
//import { BAvatar } from "bootstrap-vue";

export default {
  components: {},
  props: {
    errorData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      templateCount: 0,
    };
  },
  created() {
    this.templateCount = Object.getOwnPropertyNames(this.errorData).length - 1;
  },
};
</script>

<style lang="scss" scoped>
.toastification-close-icon,
.toastification-title {
  line-height: 26px;
}

.toastification-title {
  color: inherit;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
