<template>
  <div class="flex flex-col items-center justify-center" style="height: 600px">
    <validation-observer
      ref="val"
      tag="form"
      class="flex justify-around h-full p-5 lg:flex-row lg:mr-20"
    >
      <!-- Info About Step -->
      <div class="flex flex-col items-center justify-center lg:w-1/2">
        <div class="lg:w-2/3">
          <div class="text-xl font-bold lg:mb-3">
            Getting Your Case Type and Locations.
          </div>
          <div class="mt-2">
            This is where you can choose the counties and the type of mailing
            you want to produce.
          </div>
          <div class="mt-1">
            You can select multiple counties as well as multiple types of
            mailings if you want to do more than one mailing at a time.
          </div>
        </div>
      </div>
      <!-- Selections -->
      <div
        class="
          flex flex-col
          items-center
          justify-center
          p-2
          border
          rounded-lg
          shadow-sm
          lg:w-1/2
        "
      >
        <div>
          <div class="flex text-lg font-bold bg-gray-100 lg:p-1">
            What Type and where?
          </div>

          <!--<div v-if="$can('get', 'siteAdmin')" class="px-2 mt-10"> -->
          <div v-if="$can('post', 'claimsMarketing')" class="px-2 mt-10" >
            <div>Choose the type of export you want to perform.</div>
            <div>
              <b-form-group class="mt-1">
                <b-form-radio-group
                  v-model="selectedExportType"
                  :options="exportType"
                  :name="`radios-stacked-mailed`"
                />
              </b-form-group>
            </div>
          </div>

          <div v-if="selectedExportType === 'Claims Marketing'">
            <div class="px-2 mt-10">
              <div>Choose the State you want to include in this export.</div>
              <div>
                <validation-provider
                  #default="{ errors }"
                  name="state"
                  rules="required"
                >
                  <b-form-group
                    label-for="state"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="state"
                      v-model="selectedStates"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      multiple
                      label="state"
                      placeholder="Choose State"
                      :options="stateChoices"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      Please select AT LEAST one State.
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </div>
            </div>
            <div class="px-2 mt-10">
              <div>Choose the Court you want to include in this export.</div>

              <div>
                <validation-provider
                  #default="{ errors }"
                  name="court"
                  rules="required"
                >
                  <b-form-group
                    label-for="court"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="court"
                      v-model="selectedCourt"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      multiple
                      label="court"
                      placeholder="Choose Court"
                      :options="courtChoices"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      Please select AT LEAST one court.
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </div>
            </div>
          </div>

          <div v-else>

            <div  class="px-2 mt-10">
              
              <div>Choose the Counties you want to include in the mailing.</div>
              <div>
                <validation-provider
                  #default="{ errors }"
                  name="County"
                  rules="required"
                >
                  <b-form-group
                    label-for="county"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="county"
                      v-model="selected"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      multiple
                      label="county"
                      placeholder="Choose County"
                      :options="choices"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      Please select AT LEAST one county.
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </div>
            </div>
            
            <!-- <div v-else class="px-2 mt-10">
   
              <div>Choose the Bankruptcy Regions you want to include in the mailing.</div>
              <div>
                <validation-provider
                  #default="{ errors }"
                  name="Region"
                  rules="required"
                >
                  <b-form-group
                    label-for="region"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="region"
                      v-model="selected"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      multiple
                      label="region"
                      placeholder="Choose Region"
                      :options="regionChoices"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      Please select AT LEAST one Region.
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </div>
            </div> -->

            <!-- SECOND SELECTION -->

            <div class="px-2 mt-10">
              <div>
                Choose the case type you want to include in the mailing.
              </div>
              <div class="pb-2">
                <validation-provider
                  #default="{ errors }"
                  name="case type"
                  rules="required"
                >
                  <b-form-group
                    label-for="caseType"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="caseType"
                      v-model="selectedType"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      multiple
                      label="case_type"
                      placeholder="Choose Case Type"
                      :options="caseTypeChoices"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      Please select AT LEAST one case type.
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </div>
              <!-- END SECOND SELECTION -->
            </div>
          </div>
        </div>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { required } from "@validations";
import {
  BFormGroup,
  BFormInvalidFeedback,
  BFormRadioGroup,
} from "bootstrap-vue";

import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  name: "LocationCaseType",
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormRadioGroup,

    BFormGroup,

    vSelect,
    BFormInvalidFeedback,
  },
  props: {
    choices: {
      type: Array,
      required: true,
    },
    caseTypeChoices: {
      type: Array,
      required: true,
    },
    stateChoices: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      required,
      exportType: ["Bankruptcy Marketing", "Claims Marketing"],
      selectedExportType: "Bankruptcy Marketing",
      selectedStates: [],
      stateBusy: false,

      courtChoices: ["Select All"],
      selectedCourt: [],
      courtBusy: false,

      countyBusy: false,

      initial: true,
      initialType: true,
      option: [],
      optionType: [],

      dir: "ltr",
      selectedType: [],
      selected: [],
      bankruptcy: false,
      regionChoices: [{'county': 'TNEBK'}, {'county': 'TNEBK'}]
    };
  },

  watch: {
    selectedExportType(newValue) {
      this.$emit("selectedExportTypeEvent", newValue);
    },
    stateChoices(newValue) {
      Array.prototype.insert = function (index, item) {
        this.splice(index, 0, item);
      };
      if (this.initial) {
        newValue.insert(0, { state: "Select All" });

        this.initial = false;
      }
    },

    choices(newValue) {
      Array.prototype.insert = function (index, item) {
        this.splice(index, 0, item);
      };
      if (this.initial) {
        newValue.insert(0, { county: "Select All" });

        this.initial = false;
      }
    },
    caseTypeChoices(newValue) {
      Array.prototype.insert = function (index, item) {
        this.splice(index, 0, item);
      };

      if (this.initialType) {
        newValue.insert(0, { case_type: "Select All" });

        this.initialType = false;
      }


    },
    selectedStates(newValue, oldValue) {
      this.updateArray(
        newValue,
        oldValue,
        "state",
        "selectedStates",
        "eventState"
      );
    },
    selectedCourt(newValue, oldValue) {
      this.updateArray(
        newValue,
        oldValue,
        "court",
        "selectedCourt",
        "eventCourt"
      );
    },

    selected(newValue, oldValue) {
      this.updateArray(oldValue, newValue, "county", "selected", "eventCounty");
    },
    selectedType(newValue, oldValue) {
      Array.prototype.insert = function (index, item) {
        this.splice(index, 0, item);
      };
      // eslint-disable-next-line no-unused-vars
      var newRemove = false;
      // eslint-disable-next-line no-unused-vars
      var newRemoveI = 0;
      var newSelect = false;
      // eslint-disable-next-line no-unused-vars
      var newSelectI = 0;
      // eslint-disable-next-line no-unused-vars
      var oldRemove = false;
      // eslint-disable-next-line no-unused-vars
      var oldRemoveI = 0;
      var oldSelect = false;
      // eslint-disable-next-line no-unused-vars
      var oldSelectI = 0;

      for (const i of newValue.keys()) {
        if (newValue[i].case_type == "Remove All") {
          newRemove = true;
          newRemoveI = i;
        }
        if (newValue[i].case_type == "Select All") {
          newSelect = true;
          newSelectI = i;
        }
      }

      for (const i of oldValue.keys()) {
        if (oldValue[i].case_type == "Remove All") {
          oldRemove = true;
          oldRemoveI = i;
        }
        if (oldValue[i].case_type == "Select All") {
          oldSelect = true;
          oldSelectI = i;
        }
      }

      if (newSelect && !oldSelect && newValue.length < 2) {
        this.selectedType.splice(0, 1);
        this.selectedType = this.caseTypeChoices;
        this.selectedType.splice(0, 1);
      }

      if (newValue.some(caseObj => caseObj.case_type === "Bankruptcy")){

        if (newValue.length === oldValue.length){
          console.log("SELECTED", this.bankruptcy, newValue === oldValue)
        } else {
        this.selectedType = this.selectedType.filter(caseObj => caseObj.case_type === "Bankruptcy");
        }

      }

      if (this.selectedType.some(caseObj => caseObj.case_type === "Bankruptcy")){
        this.bankruptcy = true
      } else {
        this.bankrupcy = false
      }

      console.log(this.bankruptcy, newValue, oldValue)
      this.$emit("eventCaseType", this.selectedType);
    },
  },
  created(){
    //debugger
  },
  methods: {
    async getSelection(key, field) {
      const l1 = this;
      if (key === "state") {
        key = "court";
      } else if (key === "court") {
        key = "county";
      }
      l1[key + "Busy"] = true;

      await this.$AuthAxios.instance
        .get(`/api-access/exports/fetch-${key}/`, {
          params: { requestData: l1[field] },
        })
        .then(
          await function (res) {
            l1[res.data.key + "Choices"] = res.data.data;

            l1[key + "Busy"] = false;
          }
        )
        .catch((error) => {
          l1.busy = false;
          console.log(error);
        });
    },

    updateArray(newValue, oldValue, key, field, event) {
      Array.prototype.insert = function (index, item) {
        this.splice(index, 0, item);
      };
      // eslint-disable-next-line no-unused-vars
      var newRemove = false;
      // eslint-disable-next-line no-unused-vars
      var newRemoveI = 0;
      var newSelect = false;
      // eslint-disable-next-line no-unused-vars
      var newSelectI = 0;
      // eslint-disable-next-line no-unused-vars
      var oldRemove = false;
      // eslint-disable-next-line no-unused-vars
      var oldRemoveI = 0;
      var oldSelect = false;
      // eslint-disable-next-line no-unused-vars
      var oldSelectI = 0;

      for (const i of newValue.keys()) {
        if (newValue[i][key] == "Remove All") {
          newRemove = true;
          newRemoveI = i;
        }
        if (newValue[i][key] == "Select All") {
          newSelect = true;
          newSelectI = i;
        }
      }

      for (const i of oldValue.keys()) {
        if (oldValue[i][key] == "Remove All") {
          oldRemove = true;
          oldRemoveI = i;
        }
        if (oldValue[i][key] == "Select All") {
          oldSelect = true;
          oldSelectI = i;
        }
      }

      if (newSelect && !oldSelect && newValue.length < 2) {
        this[field].splice(0, 1);
        this[field] = this.choices;
        this[field].splice(0, 1);
      }

      this.$emit(event, this[field]);

      if (key === "state") {
        this.getSelection(key, field);
      }
    },
    async validate() {
      var status = false;
      await this.$refs.val.validate().then(
        await function (success) {
          if (success) {
            status = true;
          } else {
            status = false;
          }
        }
      );

      return status;
    },
  },
};
</script>
