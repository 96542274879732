<template>
  <section
  
    id="pricing-plan"
  > 
    <!-- title text and switch button -->
    <div class="text-center">
      <h1 class="text-4xl font-bold">
        Let us Handle this Mailing
      </h1>
      <p class="mb-2 pb-75 text-lg">
        We handle all the labor, materials, and postage for this mailing. <span class="font-bold">Guaranteed same day delivery</span> to the post office if exported before 11:59 A.M
      </p>

    </div>
    <!--/ title text and switch button -->

    <!-- pricing plan cards -->
    <b-row class="pricing-card mb-0">
      <b-col
        offset-sm-2
        sm="10"
        md="12"
        offset-lg="2"
        lg="10"
        class="mx-auto"
      >
        <b-row class="flex flex-row items-center justify-around">
   
          <b-col md="4">
            <b-card
              :class="`${selection === 'option1'?'popular': ''} hover:bg-gray-300`"
              align="center"
            >
              <div
                v-show="true"
                class="pricing-badge text-right"
              >
                <b-badge
                  variant="light-primary"
                  pill
                >
                  Popular
                </b-badge>
              </div>
              <!-- img -->
             
              <!--/ img -->
              <h3>Full Service</h3>
              <b-card-text>We handle and process all letters</b-card-text>

              <!-- annual plan -->
              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <sup class="font-medium-1 font-weight-bold text-primary">$</sup>
                  <span class="pricing-basic-value font-weight-bolder text-primary">{{ formatOption1($store.getters['auth/costPerLetter']) }}</span>
                  <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/letter</sub>
                </div>
                <small
                  v-show="false"
                  class="annual-pricing text-muted"
                >USD {{  }} / year</small>
              </div>
              <!--/ annual plan -->

              <!-- plan benefit -->
              <b-list-group class="list-group-circle text-left">
                <b-list-group-item
                
                  v-for="(data,index) in option1"
                  :key="index"
                  class="bg-transparent"
                >
                  {{ data }}
                </b-list-group-item>
              </b-list-group>
              <!--/ plan benefit -->

              <!-- buttons -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                block
                class="mt-2"
                :variant="`${selection === 'option1'?'primary': 'outline-primary'} hover:bg-gray-300`"
                @click="selectToggle('option1')"
              >
                Select
              </b-button>
            </b-card>
          </b-col>
          <b-col md="4">
            <b-card
              align="center"
              :class="`${selection === 'option2'?'popular': ''} hover:bg-gray-300`"
            >
              <!-- img -->
        
              <!--/ img -->
              <h3 class="mt-2">DIY</h3>
              <b-card-text>Process your own mailing</b-card-text>

              <!-- annual plan -->
              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <sup class="font-medium-1 font-weight-bold text-primary"></sup>
                  <span class="pricing-basic-value font-weight-bolder text-primary">?</span>
                  <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/letter</sub>
                </div>
                <small
                  v-show="false"
                  class="annual-pricing text-muted"
                >USD {{  }} / year</small>
              </div>
              <!--/ annual plan -->

              <!-- plan benefit -->
              <b-list-group
                v-for="(data,index) in option2"
                :key="index"
                class="list-group-circle text-left line-through"
              >
                <b-list-group-item class="bg-transparent">
                  {{ data }}
                </b-list-group-item>
              </b-list-group>
              <!--/ plan benefit -->

              <!-- buttons -->
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                block
                class="mt-2"
                :variant="`${selection === 'option2'?'primary': 'outline-primary'} hover:bg-gray-300`"
                @click="selectToggle('option2')"
              >
                Select
              </b-button>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!--/ pricing plan cards -->

    <!-- pricing free trial -->
    <div class="pricing-free-trial mt-0 mb-0">
      <b-row>
        <b-col
          lg="10"
          offset-lg="10"
          class="mx-auto"
        >
          <div class="pricing-trial-content d-flex justify-content-between ">
            <div class="text-center text-md-left mt-3">
              <h3 class="text-primary">
                Still not convinced? We are discounting full service for the month of August!
              </h3>
              <h5>During the month of August your full service price will be discounted by an additional <span class="text-xl font-bold bg-white rounded-full">{{cType+currencyFormat(discount)}}</span> </h5>
        
            </div>

            <!-- images -->
            <b-img
              fluid
              :src="require('@/assets/images/illustration/pricing-Illustration.svg')"
              class="pricing-trial-img"
              alt="svg img"
            />
            <!--/ images -->
          </div>
        </b-col>
      </b-row>
    </div>
    <!--/ pricing free trial -->

    <!-- pricing faq -->

    <!--/ pricing faq -->
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BImg, BCardText, BListGroup, BListGroupItem, BButton, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
/* eslint-disable global-require */
export default {
  components: {
   
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BBadge,
    BImg,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      cType: '',
        discount: '',
        selection: this.$can('post', 'fullService') ? 'option1': 'option2',
        option1: [
            "Name to Address Verification",
            "Format and Correct Names & Addresses",
            "NCOA Move Updates",
            "Handling and Disposal of Return Mail",
            "Automatically Add Returns to Do-Not-Mail List",
        ],
        option2: [
            "Name to Address Verification",
            "Format and Correct Names & Addresses",
            "NCOA Move Updates",
            "Handling and Disposal of Return Mail",
            "Automatically Add Returns to Do-Not-Mail List",
        ],
        // option2: [
        //     "NO Name to Address Verification",
        //     "NO Formatted and Corrected Names & Addresses",
        //     "NO NCOA Move Updates",
        //     "Handle and Dispose of Return Mail yourself",
        //     "Must Manually Add Returns to Do-Not-Mail List",
        // ],
      status: 'monthly',
      monthlyPlanShow: true,
      pricing: {},
    }
  },
  created() {


    this.getDiscount()

    //this.$http.get('/pricing/data').then(res => { this.pricing = res.data })
  },
  methods: {
    getDiscount() {
      
      this.$AuthAxios.instance
        .get("/api-access/exports/discounts/", {
          params: {
          },
        })
        .then((res) => {
    
            this.discount = res.data.discount
            this.cType = res.data.cType
        })
        .catch((error) => {
         console.log(error)
        });
    },
    postIsFullService(value) {
      
      this.$AuthAxios.instance
        .post("/api-access/exports/is-full-service/", {
          params: {
            is_full_service: value
          },
        })
        .catch((error) => {
         console.log(error)
        });
    },
    formatOption1(amt){
      return this.currencyFormat(amt+this.discount)
    },
    currencyFormat(v) {

      return v.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "1,")

      //return `$${("" + v).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    },
    selectToggle(selection){
        
        this.selection = selection;

        this.$store.dispatch('auth/updateFullService', this.selection);

        this.postIsFullService(this.selection === 'option1'?true:false)

    },
    tooglePlan() {

      if (this.status === 'monthly') {
        this.postIsFullService(true)
      } else {
        this.postIsFullService(false)
      }
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
</style>
