var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col items-center justify-center",staticStyle:{"height":"600px"}},[_c('validation-observer',{ref:"val",staticClass:"flex justify-around h-full p-5 lg:flex-row lg:mr-20",attrs:{"tag":"form"}},[_c('div',{staticClass:"flex flex-col items-center justify-center lg:w-1/2"},[_c('div',{staticClass:"lg:w-2/3"},[_c('div',{staticClass:"text-xl font-bold lg:mb-3"},[_vm._v(" Getting Your Case Type and Locations. ")]),_c('div',{staticClass:"mt-2"},[_vm._v(" This is where you can choose the counties and the type of mailing you want to produce. ")]),_c('div',{staticClass:"mt-1"},[_vm._v(" You can select multiple counties as well as multiple types of mailings if you want to do more than one mailing at a time. ")])])]),_c('div',{staticClass:"\n        flex flex-col\n        items-center\n        justify-center\n        p-2\n        border\n        rounded-lg\n        shadow-sm\n        lg:w-1/2\n      "},[_c('div',[_c('div',{staticClass:"flex text-lg font-bold bg-gray-100 lg:p-1"},[_vm._v(" What Type and where? ")]),(_vm.$can('post', 'claimsMarketing'))?_c('div',{staticClass:"px-2 mt-10"},[_c('div',[_vm._v("Choose the type of export you want to perform.")]),_c('div',[_c('b-form-group',{staticClass:"mt-1"},[_c('b-form-radio-group',{attrs:{"options":_vm.exportType,"name":"radios-stacked-mailed"},model:{value:(_vm.selectedExportType),callback:function ($$v) {_vm.selectedExportType=$$v},expression:"selectedExportType"}})],1)],1)]):_vm._e(),(_vm.selectedExportType === 'Claims Marketing')?_c('div',[_c('div',{staticClass:"px-2 mt-10"},[_c('div',[_vm._v("Choose the State you want to include in this export.")]),_c('div',[_c('validation-provider',{attrs:{"name":"state","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"state","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"state","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"multiple":"","label":"state","placeholder":"Choose State","options":_vm.stateChoices},model:{value:(_vm.selectedStates),callback:function ($$v) {_vm.selectedStates=$$v},expression:"selectedStates"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" Please select AT LEAST one State. ")])],1)]}}],null,false,2618113255)})],1)]),_c('div',{staticClass:"px-2 mt-10"},[_c('div',[_vm._v("Choose the Court you want to include in this export.")]),_c('div',[_c('validation-provider',{attrs:{"name":"court","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"court","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"court","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"multiple":"","label":"court","placeholder":"Choose Court","options":_vm.courtChoices},model:{value:(_vm.selectedCourt),callback:function ($$v) {_vm.selectedCourt=$$v},expression:"selectedCourt"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" Please select AT LEAST one court. ")])],1)]}}],null,false,4289835900)})],1)])]):_c('div',[_c('div',{staticClass:"px-2 mt-10"},[_c('div',[_vm._v("Choose the Counties you want to include in the mailing.")]),_c('div',[_c('validation-provider',{attrs:{"name":"County","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"county","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"county","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"multiple":"","label":"county","placeholder":"Choose County","options":_vm.choices},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" Please select AT LEAST one county. ")])],1)]}}])})],1)]),_c('div',{staticClass:"px-2 mt-10"},[_c('div',[_vm._v(" Choose the case type you want to include in the mailing. ")]),_c('div',{staticClass:"pb-2"},[_c('validation-provider',{attrs:{"name":"case type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"caseType","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"caseType","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"multiple":"","label":"case_type","placeholder":"Choose Case Type","options":_vm.caseTypeChoices},model:{value:(_vm.selectedType),callback:function ($$v) {_vm.selectedType=$$v},expression:"selectedType"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" Please select AT LEAST one case type. ")])],1)]}}])})],1)])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }