<template>
  <validation-observer ref="val" tag="form">

    {{$can('post', 'fixedFullService')}}

    <pricing v-if="!$can('post', 'fixedFullService')"/> 

    <!--<b-col class="flex flex-col w-ful"> 
        <div class=" text-4xl font-bold text-center mb-5"> 
                Let us handle your mailings
            </div>
        <b-row> 
            
            <b-col class="border rounded-lg w-128 mr-10">
                test1
            </b-col>
            <b-col class="border rounded-lg w-128">
                test2
            </b-col>

        </b-row>


    </b-col>-->
   <!--  <b-modal
      id="modal-center"
      :v-model="true"
      scrollable
      header-class="bg-white"
      hide-footer
      size="xl"
    >
      <template #modal-header="{ close }" class="bg-gray-200">
      Emulate built in modal header close button action 
        <h5 class="underline">Here are Your Results ...</h5>
        <b-button
          v-if="!closeRemoved"
          size="sm"
          variant="outline-danger"
          @click="close()"
        >
          Close Window
        </b-button>
      </template>
     <p class="my-4">{{ modalValue.item }}</p> 
      <tab-five
        ref="refTabFive"
        :initial-results="initialResults"
        :packed-filters="packedFilters"
        @UpdateInitialQueryset="updateInitialQS"
        @updateTrackedToggle="updateTrackedToggle"
        @removeRowsAll="removeRowsAll"
        @removeRow="removeRow"
        @removeClose="removeClose"
        @updateName="updateName"
        v-on="$listeners"
      >
      </tab-five>
    </b-modal> -->
  </validation-observer>
</template>

<script>
import Pricing from "./Pricing";
import { required } from "@validations";
import { ValidationObserver } from "vee-validate";
export default {
  name: "ProcessingType",
  components: {
    ValidationObserver,


    Pricing,
  },
  props: {

  },

  data() {
    return {
      required,
      initial: true,
      initialType: true,
      option: [],
      optionType: [],

      dir: "ltr",
      selectedType: [],
      selected: [],
    };
  },

  watch: {
    choices(newValue) {
      Array.prototype.insert = function (index, item) {
        this.splice(index, 0, item);
      };
      if (this.initial) {
        newValue.insert(0, { county: "Select All" });

        this.initial = false;
      }
    },
    caseTypeChoices(newValue) {
      Array.prototype.insert = function (index, item) {
        this.splice(index, 0, item);
      };

      if (this.initialType) {
        newValue.insert(0, { case_type: "Select All" });

        this.initialType = false;
      }
    },
    selected(newValue, oldValue) {
      Array.prototype.insert = function (index, item) {
        this.splice(index, 0, item);
      };
      // eslint-disable-next-line no-unused-vars
      var newRemove = false;
      // eslint-disable-next-line no-unused-vars
      var newRemoveI = 0;
      var newSelect = false;
      // eslint-disable-next-line no-unused-vars
      var newSelectI = 0;
      // eslint-disable-next-line no-unused-vars
      var oldRemove = false;
      // eslint-disable-next-line no-unused-vars
      var oldRemoveI = 0;
      var oldSelect = false;
      // eslint-disable-next-line no-unused-vars
      var oldSelectI = 0;

      for (const i of newValue.keys()) {
        if (newValue[i].county == "Remove All") {
          newRemove = true;
          newRemoveI = i;
        }
        if (newValue[i].county == "Select All") {
          newSelect = true;
          newSelectI = i;
        }
      }

      for (const i of oldValue.keys()) {
        if (oldValue[i].county == "Remove All") {
          oldRemove = true;
          oldRemoveI = i;
        }
        if (oldValue[i].county == "Select All") {
          oldSelect = true;
          oldSelectI = i;
        }
      }

      if (newSelect && !oldSelect && newValue.length < 2) {
        this.selected.splice(0, 1);
        this.selected = this.choices;
        this.selected.splice(0, 1);
      }

      this.$emit("eventCounty", this.selected);
    },
    selectedType(newValue, oldValue) {
      Array.prototype.insert = function (index, item) {
        this.splice(index, 0, item);
      };
      // eslint-disable-next-line no-unused-vars
      var newRemove = false;
      // eslint-disable-next-line no-unused-vars
      var newRemoveI = 0;
      var newSelect = false;
      // eslint-disable-next-line no-unused-vars
      var newSelectI = 0;
      // eslint-disable-next-line no-unused-vars
      var oldRemove = false;
      // eslint-disable-next-line no-unused-vars
      var oldRemoveI = 0;
      var oldSelect = false;
      // eslint-disable-next-line no-unused-vars
      var oldSelectI = 0;

      for (const i of newValue.keys()) {
        if (newValue[i].case_type == "Remove All") {
          newRemove = true;
          newRemoveI = i;
        }
        if (newValue[i].case_type == "Select All") {
          newSelect = true;
          newSelectI = i;
        }
      }

      for (const i of oldValue.keys()) {
        if (oldValue[i].case_type == "Remove All") {
          oldRemove = true;
          oldRemoveI = i;
        }
        if (oldValue[i].case_type == "Select All") {
          oldSelect = true;
          oldSelectI = i;
        }
      }

      if (newSelect && !oldSelect && newValue.length < 2) {
        this.selectedType.splice(0, 1);
        this.selectedType = this.caseTypeChoices;
        this.selectedType.splice(0, 1);
      }

      this.$emit("eventCaseType", this.selectedType);
    },
  },

  methods: {

    async validate() {
      var status = false;
      await this.$refs.val.validate().then(
        await function (success) {
          if (success) {
            status = true;
          } else {
            status = false;
          }
        }
      );

      return status;
    },
  },
};
</script>
