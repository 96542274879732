<template>
  <div class="flex flex-col w-full" style="height: 600px">
    <div v-if="!duplicates" class="w-full h-full">
      <div class="flex flex-col items-center w-full h-full">
        <div v-if="stages.length > 1" class="mb-1 text-4xl font-bold">
          Filter Cases by Date for Each Stage ..
        </div>
        <div v-else class="mb-1 text-4xl font-bold">
          Filter Cases by Date ..
        </div>
        <!-- TODO: AUTOMATICALLY REMOVE > CLOSED > DISMISSED > BANKRUPTCY.
                    * FILTER BY CLOSED?
                    * DISMISSED 
                    * BANKRUPTCY
         -->

        <div class="
            flex flex-col
            items-center
            justify-center
            w-full
            h-full
            lg:flex-row
          ">
          <div v-for="(stage, index) in stages" :key="index" class="
              flex flex-col
              items-center
              w-full
              h-full
              m-1
              border
              rounded-lg
              lg:w-1/4
            ">
            <div class="my-2 text-2xl font-bold">
              {{ stage | capitalize }}
            </div>
            <div class="flex flex-col items-center justify-center w-full h-full">
              <!-- FIXME: WORD WRAPING FOR HEADING ON EACH STAGE IS NOT WORKING CORRECTLY. MIGHT NEED TO BE CENTERED -->
              <div class="mb-2 text-sm font-bold">
                <div v-if="stage === 'hearing'" class="h-10 px-2 text-center">
                  Get Defendants With Future Hearings in the...
                </div>

                <div v-else-if="stage === 'filed'" class="h-10 px-2 text-center">
                  Get Defendants With Cases Filed in the...
                </div>
                <div v-else-if="stage === 'served'" class="h-10 px-2 text-center">
                  Get Defendants With Cases Served in the...
                </div>
                <div v-else-if="stage === 'disposed'" class="h-10 px-2 text-center">
                  Get Defendants Whose Cases Have Been Disposed in the...
                </div>
                <div v-else-if="stage == 'garnish'" class="h-10 px-2 text-center">
                  Get Defendants Whose Case has Been Upgraded to a Garnishment
                  in the...
                </div>
                <div v-else-if="stage == 'dismissed'" class="h-10 px-2 text-center">
                  Get Debtors Whose Case has Been Dismissed
                </div>
              </div>
              <div>
                <b-form-group class="mt-1">
                  <b-form-radio-group v-model="stageData[stage].dateOption" :options="stage === 'hearing' ? radioPreviousOptions : radioOptions
      " :name="`radios-stacked-${stage}`" stacked @change="updateDates()" />
                </b-form-group>
              </div>
              <div class="h-full">
                <div v-if="stageData[stage].dateOption === 'custom'">
                  <div class="">
                    <label :for="`from-input-${stage}`" class="font-bold">From</label>
                    <b-input-group class="mb-1">
                      <b-form-input :id="`from-input-${stage}`" v-model="stageData[stage].from" type="text"
                        placeholder="YYYY-MM-DD" autocomplete="off" show-decade-nav />
                      <b-input-group-append>
                        <b-form-datepicker v-model="stageData[stage].from" show-decade-nav button-only right
                          locale="en-US" :aria-controls="`from-input-${stage}`" />
                      </b-input-group-append>
                    </b-input-group>
                  </div>

                  <div class="">
                    <label :for="`to-input-${stage}`" class="font-bold">To</label>
                    <b-input-group class="mb-1">
                      <b-form-input :id="`to-input-${stage}`" v-model="stageData[stage].to" type="text"
                        placeholder="YYYY-MM-DD" autocomplete="off" show-decade-nav />
                      <b-input-group-append>
                        <b-form-datepicker v-model="stageData[stage].to" show-decade-nav button-only right
                          locale="en-US" :aria-controls="`to-input-${stage}`" />
                      </b-input-group-append>
                    </b-input-group>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="p-2 border rounded-lg mt-2 invisible" style="max-width: 900px">
          <small>
            <span class="font-bold text-red-500">ALERT: </span>
            <span>
              If you are manually selecting dates
              <span class="font-bold">you are missing records!</span> We do not
              suggest custom date selection; the courts rarely update their
              records immediately. Subsequent reporting delays are the rule, not
              the exception! We suggest always using the
              <span class="font-bold">30 days option</span>. If there are more
              records returned than you would like to export, you can
              <span class="font-bold">reduce the amount</span> at the last stage
              before final export.
            </span>
          </small>
        </div>
        <!-- <div
          v-if="stages.includes('garnish') || stages.includes('disposed')"
          class="flex flex-row justify-center w-full p-1 m-1 border rounded-lg"
        >
          <div class="self-center text-xl font-bold">
            Filter by Case / Judgement Amount :
          </div>
          <div class="ml-20 font-bold">
            <b-form-group label="Minimum Amount" label-for="minInput">
              <b-form-input
                id="minInput"
                v-model.lazy="minAmt"
                v-money="money"
              />
            </b-form-group>
          </div>
          <div class="ml-20 font-bold">
            <b-form-group label="Maximum Amount" label-for="maxInput">
              <b-form-input
                id="maxInput"
                v-model.lazy="maxAmt"
                v-money="money"
              />
            </b-form-group>
          </div>
        </div> -->
      </div>

      <div></div>
    </div>

    <div v-else class="flex justify-around h-full p-5 lg:flex-row lg:mr-20"></div>
    <b-modal id="modal-center" v-model="showModal" scrollable header-class="bg-white" hide-footer size="xl">
      <template #modal-header="{ close }" class="bg-gray-200">
        <!-- Emulate built in modal header close button action -->
        <h5 class="underline">Here are Your Results ...</h5>
        <b-button v-if="!closeRemoved" size="sm" variant="outline-danger" @click="close()">
          Close Window
        </b-button>
      </template>
      <!-- <p class="my-4">{{ modalValue.item }}</p> -->
      <tab-five ref="refTabFive" :initial-results="initialResults" :packed-filters="packedFilters"
        :county-counts="countyCounts" :claims="claims" @UpdateInitialQueryset="updateInitialQS"
        @updateTrackedToggle="updateTrackedToggle" @removeRowsAll="removeRowsAll" @removeRow="removeRow"
        @removeClose="removeClose" @updateName="updateName" v-on="$listeners">
      </tab-five>
    </b-modal>
  </div>
</template>

<script>
import {
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
} from "bootstrap-vue";
import moment from "moment";
import TabFive from "./TabFive";
import { VMoney } from "v-money";
//import { required } from "@validations"
import { BFormGroup, BFormRadioGroup, BModal, BButton } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

//import { integer } from "vee-validate/dist/rules"

//import { ValidationProvider, ValidationObserver } from "vee-validate"
export default {
  name: "LocationCaseType",

  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    BModal,
    TabFive,
  },
  directives: { money: VMoney },
  props: {
    claims: {
      type: Boolean,
      required: true,
    },
    stages: {
      type: Array,
      required: true,
    },
    tabData: {
      type: Object,
      required: true,
    },
    amounts: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fiveKey: 0,
      countyCounts: {},
      closeRemoved: false,
      packedFilters: false,
      initialResults: [],
      showModal: false,

      radioOptions: [
        { text: "Last 30 Days", value: "30" },
        { text: "Last 60 Days", value: "60" },
        { text: "Last 90 Days", value: "90" },
        { text: "Custom Range", value: "custom" },
      ],
      radioPreviousOptions: [
        { text: "Next 30 Days", value: "30" },
        { text: "Next 60 Days", value: "60" },
        { text: "Next 90 Days", value: "90" },
        { text: "Custom Range", value: "custom" },
      ],
      stageData: {
        hearing: { to: this.hearingTo(), from: this.to(), dateOption: "30" },
        disposed: { to: this.to(), from: this.from(), dateOption: "30" },
        filed: { to: this.to(), from: this.from(), dateOption: "30" },
        served: { to: this.to(), from: this.from(), dateOption: "30" },
        garnish: { to: this.to(), from: this.from(), dateOption: "30" },
        dismissed: { to: this.to(), from: this.from(), dateOption: "30" },
      },
      duplicates: false,
      minAmt: 0,
      maxAmt: 0,
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
    };
  },

  watch: {
    claims() {
      this.localPackedFilters.duplicates.value = "none";
      this.localPackedFilters.addresses.value = "10";
      this.localPackedFilters.tracked.value = "SMALL";
    },
    amounts(newValue) {
      this.minAmt = newValue.minAmt;
      this.maxAmt = newValue.maxAmt;
    },
  },

  methods: {
    fixCaps(county) {
      let cAndS = county.split(" - ");
      county = cAndS.shift();
      let fixedCounty = county.split(" ").map(word => {
        let lowerCaseWord = word.toLowerCase();
        return lowerCaseWord.charAt(0).toUpperCase() + lowerCaseWord.slice(1);
      }).join(" ");
      return fixedCounty + " - " + cAndS[0];
    },
    updateDoNotMail(uCase) {
      this.$AuthAxios.instance
        .post("/api-access/search/update-do-not-mail/", {
          params: {
            uid: uCase,
            mail_value: true,
            many: true
          },
        })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `${uCase} has been successfully added to the DO NOT MAIL LIST!`,
              icon: "MailIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `OOPS, Something went wrong..... ${error}`,
              icon: "CircleXIcon",
              variant: "danger",
            },
          });
        });
    },
    removeClose(remove) {
      this.closeRemoved = remove;
    },
    updateCounts(index, mailed, multiple, tracked) {
      this.initialResults[index].count = this.initialResults[index].count - 1;
      if (mailed) {
        this.initialResults[index].stats.previous_mailing =
          this.initialResults[index].stats.previous_mailing - 1;
      }

      if (multiple) {
        this.initialResults[index].stats.shared_addresses =
          this.initialResults[index].stats.shared_addresses - 1;
      }
      if (tracked) {
        this.initialResults[index].stats.is_tracked =
          this.initialResults[index].stats.is_tracked - 1;
      }

      this.initialResults[index].stats.total_cases =
        this.initialResults[index].stats.total_cases - 1;
      return
    },
    removeRowsAll(removalData) {
      var removedIndexes = [];
      var index = 0;
      for (const [i, value] of this.initialResults.entries()) {
        if (value.key == removalData.tableName) {
          index = i;
        }
      }

      for (const [i, value] of this.initialResults[index].results.entries()) {
        this.packedFilters.mailingType[removalData.tableName].removed.push(
          value.unique_id
        );
        removedIndexes.push(i);
      }

      this.initialResults[index].stats.previous_mailing = 0;
      this.initialResults[index].stats.shared_addresses = 0;
      this.initialResults[index].stats.is_tracked = 0;
      this.initialResults[index].stats.total_cases = 0;
      this.initialResults[index].results = [];
      this.initialResults[index].count = 0;
    },
    async updateChildAfterRemoved(key){
      //console.log('FINISHED REMOVING CHILDRED CALLING CHILD')
      this.$refs.refTabFive.$refs[key][0].updateAfterRemoved()
    },
    async removeRow(rData) {

      
      let uidArray = rData.uidArray;
      let removalIndex = 0;
      var key = ''
     

      for (const removalData of rData.rowData) {


        var index = 0;
        var rowIndex = 0;
        
        for (const [i, value] of this.initialResults.entries()) {
          if (value.key == removalData.tableName) {
            if (removalData.rowIndex > -1) {
              key = value.key
              index = i;
              rowIndex = removalData.rowIndex-removalIndex;
            }
          }
        }

        var county = this.initialResults[index].results[rowIndex].county;



        county = this.fixCaps(county)

        if (county.includes(' - undefined')) {
          county = county.replace(' - undefined', '');
        }

        //console.log(this.countyCounts)
        //console.log(county)
        //console.log(this.countyCounts[county])
        //console.log(this.countyCounts[county.toLowerCase()])



        var counts = this.countyCounts[county].count;

        this.countyCounts[county].count = counts - 1;


        await this.updateCounts(
          index,
          this.initialResults[index].results[rowIndex].previous_export,
          this.initialResults[index].results[rowIndex].defendant.multiple,
          this.initialResults[index].results[rowIndex].is_tracked
        );
        //console.log(removalData.case, rowIndex, this.initialResults[index].results[rowIndex].defendant.defendant_name)

        await this.initialResults[index].results.splice(rowIndex, 1);

        removalIndex += 1;

        this.packedFilters.mailingType[removalData.tableName].removed.push(
          removalData.case
        );

      };
      
      await this.updateDoNotMail(uidArray);
      
      this.updateChildAfterRemoved(key)
      

    },
    updateName(removalData) {
      var index = 0;
      var rowIndex = 0;

      for (const [i, value] of this.initialResults.entries()) {
        if (value.key == removalData.tableName) {
          if (removalData.rowIndex > -1) {
            index = i;
            rowIndex = removalData.rowIndex;
          }
        }
      }

      this.initialResults[index].results[rowIndex].defendant.defendant_name =
        removalData.newName;
    },
    async updateTrackedToggle(variable) {
      const l1 = this;

      for (const [i, value] of l1.initialResults.entries()) {
        if (value.key == variable.tableName) {
          l1.initialResults[i].results[variable.index].is_tracked =
            variable.tracked;
        }
      }
    },
    to() {
      //var dateOffset = 24 * 60 * 60 * 1000 * 30
      var myDate = new Date();
      //myDate.setTime(myDate.getTime() - dateOffset)
      const mnt = moment(myDate).format("YYYY-MM-DD");

      return mnt;
    },
    from() {
      var dateOffset = 24 * 60 * 60 * 1000 * 30;
      var myDate = new Date();
      myDate.setTime(myDate.getTime() - dateOffset);
      return moment(myDate).format("YYYY-MM-DD");
    },

    updateDates() {
      for (const [key, value] of Object.entries(this.stageData)) {
        var date = new Date();
        var fromDate = moment(date).format("YYYY-MM-DD");
        var toDate = moment(date).format("YYYY-MM-DD");
        if (value.dateOption !== "custom") {
          if (key === "hearing") {
            toDate = moment(
              date.setTime(
                date.getTime() +
                24 * 60 * 60 * 1000 * parseInt(value.dateOption)
              )
            ).format("YYYY-MM-DD");
          } else {
            fromDate = moment(
              date.setTime(
                date.getTime() -
                24 * 60 * 60 * 1000 * parseInt(value.dateOption)
              )
            ).format("YYYY-MM-DD");
          }

          this.stageData[key].from = fromDate;
          this.stageData[key].to = toDate;
        }
      }
    },
    hearingTo() {
      var dateOffset = 24 * 60 * 60 * 1000 * 30;
      var myDate = new Date();
      myDate.setTime(myDate.getTime() + dateOffset);
      return moment(myDate).format("YYYY-MM-DD");
    },
    serializeData() {
      var l1 = this;
      var packedFilters;

      if (!l1.packedFilters) {
        packedFilters = {};
        packedFilters.duplicates = { key: "duplicates", value: "type" };
        packedFilters.addresses = { key: "addresses", value: "combined" };
        packedFilters.tracked = { key: "tracked", value: true };
      } else {
        packedFilters = l1.packedFilters;
      }

      // counties
      var cleanCounties = [];

      l1.tabData.counties.forEach(countyClean);
      function countyClean(value) {
        if (value.county !== "Remove All" && value.county !== "Select All") {
          cleanCounties.push(value.county);
        }
      }
      packedFilters["counties"] = cleanCounties;
      // caseType
      var cleanCaseType = [];
      l1.tabData.caseType.forEach(countyCaseType);
      function countyCaseType(value) {
        if (
          value.case_type !== "Remove All" &&
          value.case_type !== "Select All"
        ) {
          cleanCaseType.push(value.case_type);
        }
      }
      packedFilters["caseType"] = cleanCaseType;

      var cleanCourt = [];
      l1.tabData.courts.forEach(courtType);
      function courtType(value) {
        if (value.court !== "Remove All" && value.court !== "Select All") {
          cleanCourt.push(value.court);
        }
      }

      packedFilters["selectedCourt"] = cleanCourt;

      // amount
      packedFilters["amount"] = { min: l1.minAmt, max: l1.maxAmt };

      // mailingType
      var mt = l1.tabData.mailingType;

      packedFilters["mailingType"] = {};

      mt.forEach(myFunction);

      function myFunction(value) {
        l1.stageData[value]["removed"] = [];
        l1.stageData[value]["ctx"] = {
          filter: "",
          sortBy: "mailed",
          sortDesc: true,
          perPage: 10,
          currentPage: 1,
          limit: 100,
          offset: 0,
          exportLimit: 0,
        };
        packedFilters["mailingType"][value] = l1.stageData[value];
      }
      packedFilters["stage"] = { key: 1, value: false };
      return packedFilters;
    },

    updateInitialQS(newValues) {
      this.countyCounts = newValues.countyCounts;

      var keys = {};
      var keyArray = [];
      var newInitialResults = [];

      newValues.responseData.forEach(function (item) {
        keys[item.key] = item;
        keyArray.push(item.key);
      });

      this.initialResults.forEach(function (item) {
        if (keyArray.includes(item.key)) {
          newInitialResults.push(keys[item.key]);
        } else {
          newInitialResults.push(item);
        }
      });

      this.initialResults = newInitialResults;
      this.packedFilters = newValues.packedFilters;
    },
    async queryExport() {
      const l1 = this;
      const packedFilters = l1.serializeData();
      l1.packedFilters = packedFilters;
      if (l1.claims) {
        packedFilters.duplicates.value = "none";
        packedFilters.addresses.value = "10";
        packedFilters.tracked.value = "SMALL";
      }
      l1.$getComponent("ExportWizard").toggleBusyOverlay(
        true,
        "Compiling Export"
      );

      await l1.$AuthAxios.instance
        .get("/api-access/exports/filter-initial-list/", {
          params: {
            filters: packedFilters,
          },
        })
        .then(
          await function (res) {
            l1.$getComponent("ExportWizard").toggleBusyOverlay(false);
            l1.fiveKey += 1;
            l1.showModal = true;

            l1.initialResults = res.data;
            l1.countyCounts = res.data[0].county_counts;
          }
        )
        .catch((error) => {
          l1.$getComponent("ExportWizard").toggleBusyOverlay(false);
          console.log(error);
        });
    },
    validate() {
      this.queryExport();

      //return this.$emit("eventUpdateFilters", {
      //  filters: this.stageData,
      //  amount: { min: this.minAmt, max: this.maxAmt }
      //})
    },
  },
};
</script>
