<template>
  <div class="flex flex-row items-center justify-around h-full w-full">
    <div class="flex flex-col h-full w-1/2 mx-2 border rounded-lg shadow-sm">
      <span class="w-full text-center font-bold bg-gray-100 mb-1">Choices</span>
      <drop-list
        :items="items1"
        mode="cut"
        class="h-full"
        @reorder="$event.apply(items1)"
        @insert="insert1"
      >
        <template v-slot:item="{ item, reorder }">
          <drag :key="item.text" :data="item" @cut="remove(items1, item)">
            <div
              :style="
                reorder
                  ? {
                      borderLeft: '5px solid #1976D2'
                    }
                  : {}
              "
            >
              <div>
                <div
                  class="bg-primary text-gray-200 my-1 mx-3 p-1 text-center shadow-sm"
                >
                  {{ item.text }}
                </div>
              </div>
            </div>
          </drag>
        </template>

        <template v-slot:feedback="{ data }">
          <div
            :key="data.text"
            class="bg-primary text-gray-200 p-1 m-1 text-center"
          >
            {{ data.text }}
          </div>
        </template>
      </drop-list>
    </div>

    <div class="flex flex-col h-full items-center justify-around">
      <feather-icon icon="ArrowRightIcon" /><feather-icon
        icon="ArrowRightIcon"
      /><feather-icon icon="ArrowRightIcon" />
    </div>
    <div class="flex flex-col h-full w-1/2 mx-2 border shadow-sm rounded-lg">
      <span class="w-full font-bold text-center bg-gray-100 mb-1"
        >Selected</span
      >
      <drop-list
        :items="items2"
        mode="cut"
        class="h-full"
        @reorder="$event.apply(items2)"
        @insert="insert2"
      >
        <slot></slot>
        <template v-slot:item="{ item, reorder }">
          <drag :key="item.text" :data="item" @cut="remove(items2, item)">
            <div
              :style="
                reorder
                  ? {
                      borderLeft: '5px solid',
                      marginLeft: '-2px'
                    }
                  : {}
              "
            >
              <div>
                <div
                  class="bg-success text-gray-200 m my-1 mx-3 p-1 text-center shadow-sm"
                >
                  {{ item.text }}
                </div>
              </div>
            </div>
          </drag>
        </template>

        <template v-slot:feedback="{ data }">
          <div
            :key="data.text"
            class="bg-success text-gray-200 p-1 m-1 text-center"
          >
            {{ data.text }}
          </div>
        </template>
      </drop-list>
    </div>
  </div>
</template>

<script>
import { Drag, DropList } from "vue-easy-dnd"

export default {
  name: "App",
  components: {
    Drag,
    DropList
  },
  props:{
    caseType: {
      type: Array,
      required:true
    }
  },
  data: function() {
    return {
      items1: [
        { text: "Recently Filed", value: "filed" },
        { text: "Recently Served", value: "served" },
        { text: "Upcoming Hearing", value: "hearing" },
        { text: "Disposed / Judgement", value: "disposed" },
        { text: "Pending Garnishemnt / Levy", value: "garnish" }
      ],
      items2: []
    }
  },
  
  watch: {
    items2() {
      this.$emit("typeMailingChange", this.items2)
    },
    caseType(newValue){
      if (newValue.some(caseObj => caseObj.case_type === "Bankruptcy")){
      this.items1 = [
      { text: "Recently Filed", value: "filed" },
      { text: "Recently Dismissed", value: "dismissed" },
      ]
    } else {
      this.items1 = [
        { text: "Recently Filed", value: "filed" },
        { text: "Recently Served", value: "served" },
        { text: "Upcoming Hearing", value: "hearing" },
        { text: "Disposed / Judgement", value: "disposed" },
        { text: "Pending Garnishemnt / Levy", value: "garnish" }
      ]
    }
    }
  },

  methods: {
    insert1(event) {
      this.items1.splice(event.index, 0, event.data)
    },
    insert2(event) {
      this.items2.splice(event.index, 0, event.data)
    },
    remove(array, value) {
      let index = array.indexOf(value)
      array.splice(index, 1)
    }
  }
}
</script>

